import React, { FC } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import s from './User.module.scss'
import Avatar from '../Avatar/Avatar'
import Text from '../Text/Text'
import { shortenAddress } from 'helpers'
import { Icon } from 'components/ui'
import { Bone } from 'components/feedback'
import { UserRoles } from 'helpers/enums'


type UserProps = {
  className?: string
  user: Profile
  isFetching: boolean
  size?: 28 | 40
}

const User: FC<UserProps> = ({ className, isFetching, user, size = 28 }) => {
  if (!user) {
    return null
  }

  if (isFetching) {
    return (
      <div className={cx('flex items-center justify-between', className)}>
        <div className={s.userLink}>
          <div className="flex items-center">
            <Bone className="mr-8" w={size} h={size} rounded />
            <Bone className="mr-8" w={100} h={20} rounded />
          </div>
        </div>
      </div>
    )
  }

  const displayedName = user.display_name || user.username || shortenAddress(user.address)

  return (
    <div className={cx('flex items-center justify-between', className)}>
      <Link href={`/user/${user.username}`}>
        <a className={s.userLink} title={displayedName}>
          <Avatar className="mr-8" size={size} address={user.address} src={user.avatar} />
          <div className={s.field}>
            <Text style={size === 40 ? 'p1' : 'p2'} message={displayedName} color="inherit" />
            {user?.roles?.includes(UserRoles.Verified) && <Icon className="ml-4" name="interface/verified" size={size === 40 ? 24 : 20} />}
          </div>
        </a>
      </Link>
    </div>
  )
}

export default User
