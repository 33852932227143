import { Icon } from 'components/ui'
import { toLocaleString } from 'helpers/formatters'
import React, { FC } from 'react'
import Text from '../Text/Text'


type EthPriceProps = {
  price: number | string
}

const EthPrice: FC<EthPriceProps> = ({ price }) => {
  return (
    <div className="flex items-center">
      <Icon name="currency/ethereum" />
      <Text className="ml-12" style="h3" message={toLocaleString(price, { maxDigits: 7, postfix: ' ETH' })} />
    </div>
  )
}

export default EthPrice
