import React from 'react'
import { useDevice } from 'device'

import ExceptMobile from './views/ExceptMobile/ExceptMobile'
import Mobile from './views/Mobile/Mobile'

import s from './Dropdown.module.scss'


export type DropdownProps = {
  dropListClassName?: string
  name: string
  content?: React.ReactElement
  className?: string
  placement?: 'bottomLeft' | 'bottomRight' | 'center' | 'arrowCenter'
  children: React.ReactElement | (({ isOpen }: { isOpen: boolean }) => React.ReactElement)
  disabled?: boolean
}

type DropdownType = React.FC<DropdownProps> & {
  Separator: React.FC
}

const Dropdown: DropdownType = (props) => {
  const { isMobile } = useDevice()

  return React.createElement(isMobile ? Mobile : ExceptMobile, props)
}

const Separator: React.VFC = () => {
  return <div className={s.separator} />
}

Dropdown.Separator = Separator

export default Dropdown
