import { getElementPosition } from 'helpers/getters'
import { devicePx } from 'helpers'


type SetMobilePositionProps = {
  trigger: HTMLElement
  tooltip: HTMLElement
}

const setMobilePosition = ({ trigger, tooltip }: SetMobilePositionProps) => {
  const verticalMobilePadding = devicePx(16)
  const viewportWidth = document.documentElement.clientWidth
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  let tooltipBounds = tooltip.getBoundingClientRect()

  let top: number

  tooltip.style.left = `${8}rem`
  tooltip.style.right = `${8}rem`

  tooltipBounds = tooltip.getBoundingClientRect()

  if (triggerBounds.top - tooltipBounds.height < verticalMobilePadding) {
    top = triggerPosition.top + triggerBounds.height + 8
    tooltip.classList.add('under')
  }
  else {
    tooltip.classList.remove('under')

    top = triggerPosition.top - tooltipBounds.height
  }

  const [ arrow ] = tooltip.getElementsByTagName('img')
  const arrowPosition = triggerPosition.left
  arrow?.setAttribute('style', `left: ${arrowPosition}px`)

  tooltip.style.top = `${top}px`
}

type SetDesktopPositionProps = {
  trigger: HTMLElement
  tooltip: HTMLElement
}

const setDesktopPosition = ({ trigger, tooltip }: SetDesktopPositionProps) => {
  const verticalPadding = 10
  const viewportWidth = document.documentElement.clientWidth
  const viewportHeight = document.documentElement.clientHeight
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  const tooltipBounds = tooltip.getBoundingClientRect()

  let top: number
  let left: number

  // if distance between viewport's top side and trigger is less than tooltip height
  // then the tooltip should be placed to the top of the trigger
  if (triggerBounds.top - tooltipBounds.height < verticalPadding) {
    tooltip.classList.add('under')

    top = triggerPosition.top + 8 + triggerBounds.height
  }
  else {
    tooltip.classList.remove('under')

    top = triggerPosition.top - tooltipBounds.height - verticalPadding
  }

  // if distance between viewport's right side and trigger is less than tooltip width
  // then the tooltip should be placed to the left of the trigger
  if (triggerBounds.right + tooltipBounds.width > viewportWidth) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width

    // if tooltip is off-screened
    if (left < 0) {
      left = verticalPadding
    }
  }
  else {
    left = triggerPosition.left + triggerBounds.width / 2 - tooltipBounds.width / 2
  }

  tooltip.style.top = `${top}px`
  tooltip.style.left = `${left}px`
}

const setTooltipPosition = ({ trigger, tooltip, isMobile }) => {
  if (isMobile) {
    setMobilePosition({ trigger, tooltip })
  }
  else {
    setDesktopPosition({ trigger, tooltip })
  }
}

type CreateTooltipProps = {
  trigger: HTMLElement
  tooltip: HTMLElement
  styles: Record<string, string>
  isMobile: boolean
}

const createTooltip = ({ trigger, tooltip, styles, isMobile }: CreateTooltipProps) => {

  const show = () => {
    // remove style attribute from previous execution
    tooltip.removeAttribute('style')

    // show tooltip to allow the script to calculate tooltip width / height
    tooltip.classList.add(styles.visible)

    setTooltipPosition({ tooltip, trigger, isMobile })

    document.addEventListener('touchstart', hide, { passive: true, capture: true })
    document.addEventListener('keydown', hideOnEscapePress)
    window.addEventListener('blur', hide)
    window.addEventListener('scroll', hide)
  }

  let timerToHide

  const _hide = (hideImmediately?: boolean) => {
    const _hide = () => {
      tooltip.classList.remove(styles.visible)

      document.removeEventListener('touchstart', hide, { capture: true })
      document.removeEventListener('keydown', hideOnEscapePress)
      window.removeEventListener('blur', hide)
      window.removeEventListener('scroll', hide)
    }

    if (hideImmediately) {
      _hide()
    }
    else {
      timerToHide = setTimeout(_hide, 50)
    }
  }

  const hide = () => _hide()
  const hideImmediately = () => _hide(true)

  const preventFromHiding = () => {
    clearTimeout(timerToHide)
  }

  const hideOnEscapePress = (event) => {
    if (event.key === 'Escape') {
      hide()
    }
  }

  if (isMobile) {
    trigger.addEventListener('touchstart', show)
  }
  else {
    trigger.addEventListener('mouseenter', show)
    trigger.addEventListener('mouseleave', hide)
    tooltip.addEventListener('mouseenter', preventFromHiding)
    tooltip.addEventListener('mouseleave', hideImmediately)
  }

  trigger.addEventListener('focus', show)
  trigger.addEventListener('blur', hide)

  // unmount callback for useEffect
  return () => {
    trigger.removeEventListener('focus', show)
    trigger.removeEventListener('touchstart', show)
    trigger.removeEventListener('mouseenter', show)
    trigger.removeEventListener('mouseleave', hide)
    trigger.removeEventListener('blur', hide)
    tooltip.removeEventListener('mouseenter', preventFromHiding)
    tooltip.removeEventListener('mouseleave', hideImmediately)

    document.removeEventListener('touchstart', hide)
    document.removeEventListener('keydown', hideOnEscapePress)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)
  }
}


export default createTooltip
