import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'


export const getMerkleTree = (allowlist: string[]) => {
  return new MerkleTree(allowlist, keccak256, { hashLeaves: true, sortPairs: true })
}

export const getProof = (tree: MerkleTree, address: string) => {
  try {
    return tree.getHexProof(keccak256(address))
  }
  catch (error) {}
}
