import { formatEther } from '@ethersproject/units'
import { useContracts } from 'contracts/client'
import { restAPI, safePromise } from 'helpers'
import { ListingState } from 'helpers/enums'
import { useQuery } from 'hooks'
import { useMemo } from 'react'


type BackendResult = {
  data: {
    creator: BackendListing[]
    participant: BackendListing[]
  }
  error: boolean
  error_message: string
}


type QueryResult = {
  participations: BackendListing[]
  creations: BackendListing[]
}

const StateMap = {
  ['ACTIVE']: ListingState.Active,
  ['RESOLVING']: ListingState.Resolving,
  ['SUCCESSFUL']: ListingState.Successful,
  ['FINALIZED']: ListingState.Finalized,
  ['CANCELED']: ListingState.Canceled,
}

const useFetchUserListings = (account: string) => {
  const fetcher = async (): Promise<QueryResult> => {
    const { data } = await restAPI.get<BackendResult>(`/listings/user/${account}`)

    const { creator, participant } = data?.data

    return {
      participations: participant,
      creations: creator,
    }
  }

  const { isFetching, data } = useQuery<QueryResult>({
    endpoint: [ '/user-listings', account ],
    fetcher,
    skip: !account,
  })

  return {
    isFetching,
    creations: data?.creations || [],
    participations: data?.participations || [],
    skip: !account,
  }
}

export default useFetchUserListings
