import { setup } from 'contracts-toolkit/client'

import { alchemyKey, infuraKey, addressesByChains, abis, getCustomProps } from './shared'
import type { Contracts } from './shared'


const { ContractsProvider, useContracts } = setup<Contracts>()({ addressesByChains, abis, infuraKey, alchemyKey }, getCustomProps)

export {
  ContractsProvider,
  useContracts,
}
