import { Contract } from '@ethersproject/contracts'
import type { ContractInterface } from '@ethersproject/contracts'
import type { JsonRpcProvider } from '@ethersproject/providers'

import coreAbi from './abis/babylonCore.json'
import tokensControllerAbi from './abis/tokensController.json'
import erc721Abi from './abis/ERC721.json'
import erc1155Abi from './abis/ERC1155.json'
import babylonMintpassAbi from './abis/babylonMintpass.json'
import babylonEditionAbi from './abis/babylonEdition.json'
import randomProviderAbi from './abis/randomProvider.json'
import creatorCoreAbi from './abis/IERC721CreatorCore.json'
import affiliateControllerAbi from './abis/affiliateController.json'

import type { BabylonCore, TokensController, ERC721, ERC1155, BabylonMintpass, BabylonEdition, RandomProvider, IERC721CreatorCore, AffiliateController } from './types'


export const infuraKey = process.env.NEXT_PUBLIC_INFURA_KEY
export const alchemyKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY

export type Contracts = {
  babylonCore: BabylonCore
  tokensController: TokensController
  babylonEdition: BabylonEdition
  randomProvider: RandomProvider
  affiliateController: AffiliateController
}

type ContractName = keyof Contracts
type Addresses = Record<ContractName, string>

export const addressesByChains = {
  1: JSON.parse(process.env.NEXT_PUBLIC_CONTRACTS_1 || null),
  5: JSON.parse(process.env.NEXT_PUBLIC_CONTRACTS_5 || null),
  11155111: JSON.parse(process.env.NEXT_PUBLIC_CONTRACTS_11155111 || null),
}

export const abis: Record<ContractName, ContractInterface> = {
  babylonCore: coreAbi,
  tokensController: tokensControllerAbi,
  babylonEdition: babylonEditionAbi,
  randomProvider: randomProviderAbi,
  affiliateController: affiliateControllerAbi,
}

export const getCustomProps = ({ addresses, provider }: { addresses: Addresses, provider: JsonRpcProvider }) => ({
  provider,

  getMintpassContract: (address: string) => {
    return new Contract(address, babylonMintpassAbi, provider) as BabylonMintpass
  },

  getERC721Contract: (address: string) => {
    return new Contract(address, erc721Abi, provider) as ERC721
  },

  getERC1155Contract: (address: string) => {
    return new Contract(address, erc1155Abi, provider) as ERC1155
  },

  getManifoldContract: (address: string) => {
    return new Contract(address, creatorCoreAbi, provider) as IERC721CreatorCore
  },
})
