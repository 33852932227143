import { useState, useEffect } from 'react'


export type UsePaginationProps = {
  data: any[]
  initialPageSize?: number
  initialPage?: number
}

const usePagination = ({ data = [], initialPageSize = 10, initialPage = 1 }: UsePaginationProps) => {
  const [ pageSize, setPageSize ] = useState(initialPageSize)
  const [ page, setPage ] = useState(initialPage)

  const totalPages = Math.ceil(data.length / pageSize)

  const paginatedData = data ? data.slice(
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize
  ) : null

  useEffect(() => {
    setPage(1)
  }, [ totalPages ])

  return { paginatedData, page, setPage, pageSize, setPageSize }
}

export default usePagination
