import React, { useEffect, useState } from 'react'
import { ConnectorNames } from 'web3'
import localStorage from '@locmod/local-storage'

import useConnect from './useConnect'
import constants from './constants'


export const ConnectingContext = React.createContext(true)

const Connector: React.FC = ({ children }) => {
  const { connect } = useConnect()
  const [ isConnecting, setConnecting ] = useState(true)

  useEffect(() => {
    const connectorName = localStorage.getItem<ConnectorNames>(constants.connectorName)

    if (connectorName) {
      if (connectorName === ConnectorNames.WalletConnect) {
        const isConnected = Boolean(localStorage.getItem<ConnectorNames>(constants.walletconnectKey))

        if (!isConnected) {
          // saved but not actually connected, cleanup
          localStorage.removeItem(constants.connectorName)
          setConnecting(false)

          return
        }
      }

      connect(connectorName).finally(() => {
        setConnecting(false)
      })
    }
    else {
      setConnecting(false)
    }
  }, [])

  return (
    <ConnectingContext.Provider value={isConnecting}>
      {children}
    </ConnectingContext.Provider>
  )
}


export default Connector
