import React, { memo } from 'react'
import Link from 'next/link'

import s from './Logo.module.scss'


// const Logo: React.FC = () => {
//   return (
//     <Link href="/" passHref>
//       <a className="flex items-center">
//         <img className={s.symbol} src="/images/logo-symbol.png" alt="" />
//         <img className={s.text} src="/images/logo-text.svg" alt="" />
//       </a>
//     </Link>
//   )
// }

const Logo: React.FC = () => {
  return (
    <Link href="/" passHref>
      <a className={s.logo} title="Babylon">
        <img src="/images/logos/logo.svg" alt="Babylon logo" />
      </a>
    </Link>
  )
}

export default memo(Logo)
