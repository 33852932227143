import { useEffect } from 'react'


let openedModalsCount = 0

const useFreezeBodyScroll = (enabled = true) => {
  const addStyles = () => {
    document.body.style.overflow = 'hidden'
  }

  const removeStyles = () => {
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    if (enabled) {
      if (openedModalsCount === 0) {
        addStyles()
      }
      openedModalsCount++

      return () => {
        openedModalsCount--

        if (openedModalsCount === 0) {
          removeStyles()
        }
      }
    }
  }, [ addStyles, removeStyles, enabled ])
}


export default useFreezeBodyScroll
