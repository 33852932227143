import React from 'react'
import { useDevice } from 'device'

import NotificationBase from '../NotificationBase/NotificationBase'
import type { NotificationBaseProps } from '../NotificationBase/NotificationBase'

import s from './PlainNotification.module.scss'
import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'


type PlainNotificationProps = NotificationBaseProps & {
  title: string
  text: string
  error?: string
  children?: React.ReactNode
}

const PlainNotification: React.FC<PlainNotificationProps> = ({ title, text, error, children, closeNotification }) => {
  const { isMobile } = useDevice()
  const titleClass = isMobile ? 'u1' : 'u2'
  const textClass = isMobile ? 'c1' : 'c2'
  const titleColor = error ? 'accent-red-50' : 'brand-50'

  return (
    <NotificationBase closeNotification={closeNotification} autoHideSeconds={error ? 15 : 5}>
      <div>
        <Text className={s.title} style={titleClass} message={title} color={titleColor} />
        <div className={s.closeIcon} onClick={closeNotification}>
          <Icon name="interface/close" size={16} color="gray-40" />
        </div>
      </div>
      <div className={s.text}>
        <Text
          className="opacity-06"
          style={textClass}
          message={text}
          color="gray-50"
          html
        />
      </div>
      {children}
    </NotificationBase>
  )
}


export default PlainNotification
