// converts iPhone XR size in pixels to vwx device size
const devicePx = (value: number): number => {
  if (typeof window !== 'undefined' && typeof window.innerWidth === 'number') {
    const fontSize = document.documentElement.style.fontSize

    return value * (fontSize ? parseFloat(fontSize) : 1)
  }

  return value
}


export default devicePx
