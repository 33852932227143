import React from 'react'
import { Avatar, Text } from 'components/dataDisplay'
import { useProfile } from 'context'
import { constants, localStorage, shortenAddress } from 'helpers'
import { useConnect } from 'web3'
import { useChainId } from 'chain-id'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { Dropdown } from 'components/inputs'
import AccountDropdown from 'compositions/PageLayout/components/AccountDropdown/AccountDropdown'
import s from './AccountButton.module.scss'
import { useDevice } from 'device'


const Profile: React.FC = () => {
  const { account } = useConnect()
  const { selectedChainId } = useChainId()
  const { isExisting, profile } = useProfile()
  const { isMobile } = useDevice()
  const router = useRouter()

  const skippedOnboardng = localStorage.getItem<boolean>(`${constants.localStorage.skippedOnboarding}-${account}`)

  if (isExisting === false && !skippedOnboardng && router.asPath !== '/onboarding') {
    router.push('/onboarding')
  }

  return (
    <Dropdown
      name="AccountDropdown"
      content={<AccountDropdown />}
      placement="bottomRight"
    >
      {
        ({ isOpen }) => {
          return (
            <div className={cx(s.container, isOpen && s.open)} role="button">
              <Avatar
                address={account}
                size={28}
                src={profile?.avatar}
              />
              {
                !isMobile && (
                  <div className="ml-8">
                    <Text style="c3" message={profile?.display_name || profile?.username || shortenAddress(account)} color="white-100" />
                    <Text className={s.chain} style="c3" message={constants.chainNames[selectedChainId]} color="white-100" />
                  </div>
                )
              }
            </div>
          )
        }
      }
    </Dropdown>
  )
}

export default Profile
