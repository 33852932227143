import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider as EthersProvider } from '@ethersproject/providers'


const getWeb3ReactLibrary = (provider) => {
  const lib = new EthersProvider(provider) // this will vary according to whether you use e.g. ethers or web3.js
  lib.pollingInterval = 12000

  return lib
}

const Web3Provider: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getWeb3ReactLibrary}>
    {children}
  </Web3ReactProvider>
)

export default Web3Provider
