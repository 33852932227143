import React, { useState } from 'react'


const ImageFallback: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ className, src, alt }) => {
  const [ isError, setIsError ] = useState(false)

  const handleError = () => {
    setIsError(true)
  }

  return (
    <>
      {
        isError ? (
          <img className={className} src="/images/loading-error.svg" alt={alt} />
        ) : (
          <img
            className={className}
            src={src}
            alt={alt}
            onError={handleError}
            loading="lazy"
          />
        )
      }
    </>
  )
}

export default ImageFallback
