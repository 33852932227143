import React, { forwardRef, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useUniqueId } from 'hooks'
import { useDevice } from 'device'
import cx from 'classnames'
import { Text } from 'components/dataDisplay'

import createTooltip from './util/createTooltip'
import s from './Tooltip.module.scss'


export type TooltipProps = {
  title?: string
  text?: string
}

type TooltipRefProps = TooltipProps & {
  id: string
}

const Tooltip = forwardRef<HTMLDivElement, TooltipRefProps>(({ id, title, text }, ref) => createPortal(
  <div ref={ref} id={id} className={s.tooltip} role="tooltip">
    {/* <img src="/images/arrow_tooltip.svg" alt="" className={s.arrow} /> */}
    <div className={cx('mt-4', s.title)}>
      {title}
    </div>
    {
      Boolean(text) && (
        <>
          {
            React.createElement('div', {
              className: 'mt-4',
              dangerouslySetInnerHTML: { __html: text },
            })
          }
        </>
      )
    }
  </div>,
  document.getElementById('tooltips')
))

const TooltipWrapper = ({ children, title, text }: { children: React.ReactChild } & TooltipProps) => {
  const { isMobile } = useDevice()

  const id = useUniqueId('tooltip')
  const triggerRef = useRef<HTMLElement>()
  const tooltipRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!triggerRef.current) {
      console.error('triggerRef for Tooltip is undefined', {
        extra: {
          children,
          text,
        },
      })

      return
    }

    return createTooltip({
      trigger: triggerRef.current,
      tooltip: tooltipRef.current,
      styles: s,
      isMobile,
    })
  }, [ children, text, isMobile ])

  if (typeof document === 'undefined') {
    return <></>
  }

  return (
    <>
      <Tooltip ref={tooltipRef} id={id} title={title} text={text} />
      {
        // @ts-ignore
        React.cloneElement(children, {
          ref: triggerRef,
          'aria-describedby': id,
        })
      }
    </>
  )
}


export default React.memo(TooltipWrapper)
