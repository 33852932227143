import React, { FC, useState } from 'react'
import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'
import { event, jwt, shortenAddress } from 'helpers'
import { useConnect } from 'web3'
import s from './AccountDropdown.module.scss'
import copy from 'copy-to-clipboard'
import { toLocaleString } from 'helpers/formatters'
import { Button, IconButton } from 'components/inputs'
import { useBalance, useScanAddress } from 'hooks'
import { useRouter } from 'next/router'
import { useProfile } from 'context'


type AccountDropdownProps = {

}

const AccountDropdown: FC<AccountDropdownProps> = ({}) => {
  const { account, disconnect } = useConnect()
  const { profile } = useProfile()
  const router = useRouter()
  const { isBalanceFetching, balance } = useBalance()
  const [ isCopied, setIsCopied ] = useState(false)

  const url = useScanAddress(account)
  const { isAccount, isAdmin } = jwt()

  const isCurrentAccount = isAccount(account)
  const profileLink = profile?.username ? `/user/${profile.username}` : '/onboarding'

  const handleCopy = () => {
    copy(account)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  const handleDisconnect = () => {
    disconnect()
    router.push('/')
  }

  return (
    <div className={s.container}>
      <div className={s.account}>
        <div className="p-8 flex justify-between">
          <Text style="c3" message={shortenAddress(account)} color="gray-90" />
          <div className="flex">
            <IconButton
              size={12}
              name={isCopied ? 'interface/check' : 'interface/copy'}
              onClick={handleCopy}
              color="gray-50"
            />
            <a className="ml-12 flex items-center justify-center" href={url} target="_blank" rel="noreferrer">
              <IconButton
                size={12}
                color="gray-50"
                name="interface/external-link"
              />
            </a>
          </div>
        </div>
        <div className={s.divider} />
        <div className="p-8 flex justify-between">
          <Text style="u3" message="Balance" />
          {
            isBalanceFetching ? (
              <div className="flex items-center">
                <Icon name="interface/loader" size={12} color="brand-50" />
                <Text className="ml-8" style="u3" message={'ETH'} />
              </div>
            ) : (
              <Text style="u3" message={toLocaleString(balance, { currency: 'ETH' })} />
            )
          }
        </div>
      </div>

      <div className="pb-8 pl-8 pr-8">
        <Button
          className="mt-12"
          size={32}
          title="List editions"
          leftIcon="interface/plus"
          onClick={() => event({ event: 'list_editions', version: 'header' })}
          fullWidth
          to="/create"
        />
        <Button
          className="mt-8"
          size={32}
          title="My profile"
          leftIcon="interface/profile"
          fullWidth
          style="tertiary"
          to={profileLink}
        />
        <Button
          className="mt-8"
          size={32}
          title="Edit profile"
          leftIcon="interface/edit"
          style="tertiary"
          fullWidth
          to="/profile/edit"
        />
        <Button
          className="mt-8"
          size={32}
          title="log out"
          leftIcon="interface/logout"
          fullWidth
          style="tertiary"
          onClick={handleDisconnect}
        />

        {
          isCurrentAccount && isAdmin && (
            <Button
              className="mt-8"
              size={32}
              title="admin page"
              leftIcon="interface/settings"
              fullWidth
              style="secondary"
              to="/admin"
            />
          )
        }
      </div>
    </div>
  )
}

export default AccountDropdown
