import React, { Children } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import type { LinkProps } from 'next/link'
import cx from 'classnames'


type ActiveLinkProps = LinkProps & {
  className?: string
  activeClassName: string
  exact?: boolean
}

const ActiveLink: React.FC<ActiveLinkProps> = (props) => {
  const { children, className, activeClassName, exact, ...rest } = props

  const { asPath } = useRouter()

  const child = Children.only(children) as React.ReactElement
  const childClassName = child.props.className || ''
  const url = String(rest.href || rest.as || '')
  const isActive = exact ? url === asPath : asPath.includes(url)

  const linkClassName = cx(className, childClassName, {
    [activeClassName]: isActive,
  })

  return (
    <Link {...rest}>
      {
        React.cloneElement(child, {
          className: linkClassName || null,
        })
      }
    </Link>
  )
}


export default ActiveLink
