import React from 'react'
import { Notifications as NotificationsConductor } from '@locmod/notifications'
import cx from 'classnames'

import PlainNotification from './PlainNotification/PlainNotification'

import s from './Notifications.module.scss'


export const templates = {
  'plain': PlainNotification,
} as const

const Notifications = () => (
  <NotificationsConductor
    className={cx(s.notifications, 'flex flex-col')}
    templates={templates}
  />
)


export default Notifications
