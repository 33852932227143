import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import type { IconName } from './icons'

import InlineSvg from '../InlineSvg/InlineSvg'

import s from './Icon.module.scss'
import { colors } from 'components/dataDisplay/Text/Text'


export const sizes = [ 12, 14, 16, 20, 24, 48 ]

export type IconSize = typeof sizes[number]

export type IconColor = typeof colors[number]

export type IconProps = {
  className?: string
  name: IconName
  mobSize?: IconSize
  size?: IconSize
  color?: IconColor
  'aria-describedby'?: string
}

const IconBase: React.FunctionComponent<IconProps> = (props) => {
  const { className, name, size, color, 'aria-describedby': ariaDescribedby } = props

  const rootClassName = cx(s.icon, className, s[name], s[`size-${size}`], {
    [`color-${color}`]: color,
  })

  return (
    <InlineSvg
      className={rootClassName}
      src={`/images/icons/${name}.svg`}
      aria-describedby={ariaDescribedby}
    />
  )
}

const MediaIcon: React.FunctionComponent<IconProps> = (props) => {
  const { size, mobSize, ...rest } = props

  const { isMobile } = useDevice()

  return <IconBase {...rest} size={isMobile ? mobSize : size} />
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const { mobSize } = props

  if (mobSize) {
    return <MediaIcon {...props} />
  }

  return <IconBase {...props} />
}


export default Icon
