import { shortenAddress } from 'helpers'
import React, { FC } from 'react'
import { useScanAddress } from 'hooks'
import Text from '../Text/Text'
import s from './AddressLink.module.scss'
import { Bone } from 'components/feedback'
import { Icon } from 'components/ui'


type AddressLinkProps = {
  address: Address
  isFetching?: boolean
}

const AddressLink: FC<AddressLinkProps> = ({ isFetching, address }) => {
  const url = useScanAddress(address)

  if (isFetching) {
    return (
      <div className={s.url}>
        <Bone h={12} w={100} rounded />
      </div>
    )
  }

  if (!address) {
    return null
  }

  return (
    <a className={s.url} href={url} target="_blank" rel="noreferrer">
      <Text style="p2" message={shortenAddress(address)} color="gray-90" />
      <Icon className="ml-4" name="interface/forward" />
    </a>
  )
}

export default AddressLink
