const preferredChainId = +process.env.NEXT_PUBLIC_PREFERRED_CHAIN_ID
const supportedChainIds = JSON.parse(process.env.NEXT_PUBLIC_SUPPORTED_CHAIN_IDS) as number[]

if (typeof window === 'undefined') {
  console.log('preferred chain ID:', preferredChainId)
  console.log('supported chain IDs:', supportedChainIds)
}

type ChainsMap = {
  [chainId: number]: {
    chainName: string
    nativeCurrency: { name: string, symbol: string, decimals: number }
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
}

const chainsMap: ChainsMap = {
  1: {
    chainName: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: [ 'https://mainnet.infura.io/v3/' ],
    blockExplorerUrls: [ 'https://etherscan.io' ],
  },
  5: {
    chainName: 'Goerli',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: [ 'https://goerli.infura.io/v3/' ],
    blockExplorerUrls: [ 'https://goerli.etherscan.io' ],
  },
  11155111: {
    chainName: 'Sepolia',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: [ 'https://sepolia.infura.io/v3/' ],
    blockExplorerUrls: [ 'https://sepolia.etherscan.io' ],
  },
}

export default {
  connectorName: 'babylon-connector-name',
  selectedChainIdKey: 'babylon-selected-chain',
  walletconnectKey: 'walletconnect',
  preferredChainId,
  supportedChainIds,
  chainsMap,
}
