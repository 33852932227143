import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import s from './Avatar.module.scss'


const sizes = [ 14, 18, 28, 40, 180 ] as const

export type AvatarSize = typeof sizes[number]

type AvatarProps = {
  className?: string
  src?: string
  address?: string
  size: AvatarSize
  mobSize?: AvatarSize
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, src, address, size, mobSize } = props

  const { isMobile } = useDevice()

  const sizeClassName = s[`size-${mobSize && isMobile ? mobSize : size}`]
  const rootClassName = cx(s.avatar, className, sizeClassName)

  if (!src && !address) {
    return null
  }

  return (
    <div className={rootClassName}>
      <div className={cx(s.inner)}>
        {
          src ? (
            <img
              loading="lazy"
              className={s.image}
              src={src}
              alt="profile picture"
            />
          ) : (
            <Jazzicon diameter={size} seed={jsNumberForAddress(address)} />
          )
        }
      </div>
    </div>
  )
}

export default Avatar
