const pinataGateway = 'https://gateway.pinata.cloud/ipfs/'
const ipfsGateway = 'https://gateway.ipfs.io/ipfs/'
const cfGateway = 'https://cloudflare-ipfs.com/ipfs/'

// this function cleans up ipfs urls and converts it into a proper url

const getIpfsUrl = (ipfsUrl: string) => {
  if (!ipfsUrl) {
    return
  }

  // fix ipfs://
  if (ipfsUrl.startsWith('ipfs://')) {
    ipfsUrl = ipfsUrl.replace(/ipfs:?\/\//g, '')

    return cfGateway + ipfsUrl
  }

  // in case there's no prefix
  if (ipfsUrl.startsWith('Qm')) {
    return cfGateway + ipfsUrl
  }

  return ipfsUrl
}

export default getIpfsUrl
