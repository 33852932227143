import { utils, constants } from 'ethers'


export const codeNode = (code: string) => {
  if (!code) {
    return constants.HashZero
  }

  return utils.keccak256(utils.toUtf8Bytes(code))
}
