import React from 'react'
import { useDevice } from 'device'

import type { TextProps } from './Text'
import Text from './Text'


const TextProxy: React.FC<TextProps> = (props) => {
  const { style, mobStyle } = props

  const { isMobile } = useDevice()

  const textStyle = isMobile ? mobStyle : style

  return (
    <Text {...props} style={textStyle} mobStyle={undefined} />
  )
}

export default TextProxy
