import axios from 'axios'
import https from 'https'


const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL + 'api/v1',
  httpsAgent: new https.Agent({ rejectUnauthorized: false, keepAlive: true }),
})

export default instance
