import React from 'react'

import { Text } from 'components/dataDisplay'
import { Logo } from 'compositions/PageLayout/components/Header/components'
import { Icon, IconName } from 'components/ui'
import { constants } from 'helpers'
import { otherItems } from 'compositions/PageLayout/components/Header/components/MobileMenu/MobileMenu'


const Desktop: React.FC = () => {
  const FooterLink: React.FC<{ url: string, title: string }> = ({ children, url, title }) => {
    return (
      <a href={url} target="_blank" rel="noreferrer" title={title}>
        {children}
      </a>
    )
  }

  const SocialLink: React.FC<{ className?: string, icon: IconName, url: string }> = ({ className, icon, url }) => {
    return (
      <a
        href={url}
        className={className}
        target="_blank"
        rel="noreferrer"
        title={`Babylon ${icon.split('/')[1]}`}
      >
        <Icon name={icon} />
      </a>
    )
  }

  return (
    <div className="flex w-full items-center justify-between">
      <Logo />
      <div className="flex uppercase">
        {
          otherItems.map(item => (
            <FooterLink key={item.href} url={item.href} title={item.title}>
              <Text className="mx-20 hover:underline" style="u1" message={item.title} />
            </FooterLink>
          ))
        }
      </div>
      <div className="flex">
        <SocialLink icon="footer/twitter" url={constants.urls.twitter} />
        <SocialLink className="ml-24" icon="footer/instagram" url={constants.urls.instagram} />
        <SocialLink className="ml-24" icon="footer/medium" url={constants.urls.medium} />
      </div>
    </div>
  )
}

export default Desktop
