import { Text } from 'components/dataDisplay'
import { ActiveLink } from 'components/navigation'
import { Icon } from 'components/ui'
import React, { FC } from 'react'
import s from './MobileMenu.module.scss'
import cx from 'classnames'
import { useFreezeBodyScroll } from 'hooks'
import { constants } from 'helpers'
import { navItems } from '../navItems'
import Logo from '../Logo/Logo'
import { WidthContainer } from 'components/layout'


type MobileMenuProps = {
  handleClose: () => void
}

export const otherItems = [
  { title: 'Privacy Policy', href: constants.urls.privacyPolicy },
  { title: 'Terms of Service', href: constants.urls.termsOfService },
  { title: 'Community & Support', href: constants.urls.support },
]

const MobileMenu: FC<MobileMenuProps> = ({ handleClose }) => {
  useFreezeBodyScroll()

  return (
    <div className={s.container}>
      <WidthContainer>
        <div className={s.topBar}>
          <div className={s.closeBtn} onClick={handleClose}>
            <Icon name="interface/close" size={24} />
          </div>
          <Logo />
        </div>

        <div className={s.menu}>
          {
            navItems.map(item => (
              <ActiveLink
                href={item.href}
                key={item.href}
                activeClassName={s.active}
                exact
              >
                <a className={cx(s.item, 'cursor-pointer')} onClick={() => handleClose()} target={item.inTab ? '_blank' : undefined}>
                  <Text message={item.title} style="u1" color="inherit" />
                </a>
              </ActiveLink>
            ))
          }
        </div>
        <div className={s.divider} />
        <div className={cx(s.menu)}>
          {
            otherItems.map(item => (
              <a
                key={item.href}
                className={cx(s.item, 'cursor-pointer')}
                target="_blank"
                href={item.href}
                onClick={() => handleClose()}
                rel="noreferrer"
                title={item.title}
              >
                <Text message={item.title} style="u2" color="gray-40" />
              </a>
            ))
          }
        </div>
      </WidthContainer>
    </div>
  )
}

export default MobileMenu
