import React from 'react'
import { useDevice } from 'device'

import { WidthContainer } from 'components/layout'
import WrongNetworkBanner from 'compositions/WrongNetworkBanner/WrongNetworkBanner'
import OnboardingBanner from 'compositions/OnboardingBanner/OnboardingBanner'
import Babylon7Banner from 'compositions/Babylon7Banner/Babylon7Banner'

import Desktop from './views/Desktop/Desktop'
import Mobile from './views/Mobile/Mobile'

import s from './Header.module.scss'


const Header: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <header className={s.header}>
      <Babylon7Banner />
      <WrongNetworkBanner />
      <div className={s.inner}>
        <WidthContainer>
          {isMobile ? <Mobile /> : <Desktop />}
        </WidthContainer>
      </div>
      <OnboardingBanner />
    </header>
  )
}

export default Header
