import { WidthContainer } from 'components/layout'
import s from './Babylon7Banner.module.scss'
import React, { useEffect, useState } from 'react'
import B7Icon from './Icons/B7Icon'
import PlusIcon from 'compositions/Babylon7Banner/Icons/PlusIcon'
import AddToCalendarModal, { openAddToCalendarModal } from 'compositions/modals/AddToCalendarModal/AddToCalendarModal'
import constants from 'helpers/constants'


const Babylon7Banner = () => {
  const startDate = new Date('Jun 20 2023 15:00:00 GMT-0000').getTime()
  const [ time, setTime ] = useState<string | null>('')
  const event = {
    title: 'Babylon 7 launch',
    description: '',
    location: constants.urls.twitter,
    start: startDate/1000,
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = startDate - new Date().getTime()
      let text = null

      if (diff > 0) {
        const d = Math.floor(diff / (1000 * 60 * 60 * 24))
        const h = Math.floor((diff / (1000 * 60 * 60)) % 24)
        const m = Math.floor((diff / 1000 / 60) % 60)
        const s = Math.floor((diff / 1000) % 60)
        text = `{ ${d > 9 ? d : '0' + d}d : ${h > 9 ? h : '0' + h}h : ${m > 9 ? m : '0' + m}m : ${s > 9 ? s : '0' + s}s }`
      }

      setTime(text)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const handleClick = () => {
    if (time) {
      openAddToCalendarModal({ title: 'Don’t miss the launch.', event, eventName: 'add_calendar' } )
    }
    else {
      window.open('https://7.babylon.art')
    }
  }

  return (
    <>
      <AddToCalendarModal />
      <div className={s.bg} onClick={handleClick}>
        <WidthContainer className={s.container}>
          <PlusIcon className={s.plus} />
          <div>
            <div className={s.logo}>
              <B7Icon />
            </div>
            <div className={time === null ? s.glich : s.countdown}>
              {time === null ? 'Live Now' : time}
            </div>
          </div>
          <PlusIcon className={s.plus} />
        </WidthContainer>
      </div>
    </>
  )
}

export default Babylon7Banner
