import { RefObject, useEffect, useState } from 'react'


type IntersectionObserverCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
) => void

type UseOnScreen = (
  ref: RefObject<Element>,
  rootMargin?: string,
  onIntersect?: IntersectionObserverCallback,
) => boolean

const useOnScreen: UseOnScreen = (ref, rootMargin = '100px') => {
  // State and setter for storing whether element is visible
  const [ isIntersecting, setIntersecting ] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([ entry ]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export default useOnScreen
