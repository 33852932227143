import React from 'react'
import { useConnect, constants as web3constands } from 'web3'
import { requestMetamaskToChangeNetwork } from 'chain-id'
import cx from 'classnames'

import { WidthContainer } from 'components/layout'
import { Text } from 'components/dataDisplay'

import s from './WrongNetworkBanner.module.scss'
import { constants } from 'helpers'
import { Button } from 'components/inputs'
import { useDevice } from 'device'


const WrongNetworkBanner = () => {
  const { isActive, chainId, isSupportedNetwork } = useConnect()
  const { isMobile } = useDevice()

  if (isActive && isSupportedNetwork) {
    return null
  }

  if (!chainId) {
    return null
  }

  const chainName = constants.chainNames[web3constands.preferredChainId]

  return (
    <div className={cx(s.wrongNetwork, 'cursor-pointer')} onClick={() => requestMetamaskToChangeNetwork(web3constands.preferredChainId)}>
      <WidthContainer className={cx('flex items-center justify-between', isMobile && 'flex-col')}>
        <Text
          message={`Wrong network. Looks like you connected to unsupported network. Change network to ${chainName}`}
          style="p1"
          color="white-100"
          tag="span"
          html
        />
        <Button
          className={isMobile ? 'mt-16' : 'ml-8'}
          style="action"
          size={32}
          title="Change chain"
          fullWidthOnMobile
        />
      </WidthContainer>
    </div>
  )
}


export default React.memo(WrongNetworkBanner)
