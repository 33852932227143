import { constants, restAPI } from 'helpers'
import { useQuery } from 'hooks'


type ProfileResult = {
  data: Profile
  error: boolean
  error_message: string
}

type FetchUserByUsernameProps = {
  username?: string
  address?: string
}

type Props = AtLeastOne<FetchUserByUsernameProps, 'address' | 'username'>

const useFetchUser = ({ username, address }: Props) => {
  const isNullAddress = address === constants.nullAddress
  const endpoint = username ? `/user?username=${username}` : `/user?address=${address}`

  const fetcher = async () => {
    const { data } = await restAPI.get<ProfileResult>(endpoint)

    return data
  }

  const { isFetching, data } = useQuery<ProfileResult>({
    endpoint,
    fetcher,
    skip: !username && !address || isNullAddress,
  })

  return {
    isFetching,
    data: data?.data,
  }
}

export default useFetchUser
