import { utils } from 'ethers'
import type { ContractTransaction, ContractReceipt } from 'ethers'


const waitTx = async (tx: ContractTransaction): Promise<ContractReceipt> => {
  try {
    return await tx.wait()
  }
  catch (err) {
    // if transaction was replaced (gas increased) then it's ok to continue a submit flow bcs the tx will be mined
    // https://blog.ricmoo.com/highlights-ethers-js-may-2021-2826e858277d
    const isReplaced = err.code === utils.Logger.errors.TRANSACTION_REPLACED && !err.cancelled

    if (isReplaced) {
      return Promise.resolve(err.receipt) as Promise<ContractReceipt>
    }

    return Promise.reject(err)
  }
}

export default waitTx
