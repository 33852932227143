import type { FC } from 'react'
import React from 'react'
import Text from '../Text/Text'
import cx from 'classnames'
import s from './NFTCard.module.scss'
import ImageFallback from '../ImageFallback/ImageFallback'


type NFTCardProps = {
  nft: NFT
}

const NFTCard: FC<NFTCardProps> = ({ nft }) => {
  const { title, mediaUrl, contractName } = nft || {}

  return (
    <div className={cx(s.container)}>
      <div className={cx(s.mediaContainer)}>
        <div className={s.overlay}>
          <Text style="c1" color="white-100" tag="p" message={contractName} />
          <Text
            className="mt-4 opacity-06"
            style="c1"
            color="white-100"
            message={title}
            tag="p"
          />
        </div>
        <ImageFallback
          loading="lazy"
          className={cx(s.media)}
          src={mediaUrl}
          alt={title}
        />
      </div>
    </div>
  )
}

export default NFTCard
