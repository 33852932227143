const B7Icon = () => (
  <svg viewBox="0 0 144 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="144"
      height="45"
    >
      <path d="M29.1113 11.9986V8.53186H45.7127C42.321 16.654 37.0252 22.2669 37.0252 30.8056C35.3701 30.1967 33.5564 30.1723 31.8855 30.7365L31.6809 30.8056C31.9044 23.15 45.4737 14.9 43.2568 9.88257C42.9638 9.21944 42.2086 8.94838 41.4836 8.94838H39.5899C38.1137 8.94838 37.256 10.618 38.1157 11.818C38.1607 11.8808 38.1039 11.9658 38.0287 11.9483L37.0442 11.7194C34.8297 11.2046 32.5267 11.2046 30.3121 11.7194L29.1113 11.9986Z" fill="white" />
      <path d="M17.9927 26.0604C23.1681 25.2814 25.311 22.9443 25.311 19.0492C25.2706 12.7001 19.8122 11.6874 13.545 11.6874H2.34511V28.787C3.8007 28.6312 5.86278 28.4364 8.20789 28.9039V26.411H12.1703C15.8093 26.411 21.3082 28.0469 21.3082 35.4477C21.3082 40.8619 17.2649 44.835 12.7364 44.835C8.20789 44.835 5.57975 41.7188 5.57975 37.2005C5.57975 31.163 11.0382 31.163 13.7876 34.1623C13.9898 34.357 14.1515 34.5908 14.3133 34.8245C14.4346 34.9803 14.6367 35.0582 14.7985 34.9413C14.9602 34.8634 15.0006 34.6297 14.9198 34.4739C11.5234 28.5923 0 28.9818 0 37.2394C0 42.1863 5.13499 45.6529 12.7364 45.6529C20.3378 45.6529 27.6562 42.1084 27.6562 35.4866C27.6562 29.4881 23.1681 26.9173 17.9522 26.0604H17.9927ZM11.766 25.632H8.20789V12.4664H11.3617C15.8902 12.4664 19.3674 13.9855 19.3674 19.0492C19.3674 23.3338 17.3053 25.632 11.766 25.632Z" fill="white" />
    </mask>
    <g mask="url(#mask0_21_775)">
      <rect y="39.0986" width="143.538" height="6.083" fill="black" />
    </g>
    <mask
      id="mask1_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="39"
      width="144"
      height="7"
    >
      <rect y="39.0988" width="143.538" height="6.083" fill="#0C0C0C" />
    </mask>
    <g mask="url(#mask1_21_775)">
      <path d="M17.9927 25.9745C23.1681 25.1955 25.311 22.8584 25.311 18.9632C25.2706 12.6142 19.8122 11.6014 13.545 11.6014H2.34511V28.7011C3.8007 28.5453 5.86278 28.3505 8.20789 28.8179V26.325H12.1703C15.8093 26.325 21.3082 27.961 21.3082 35.3617C21.3082 40.776 17.2649 44.749 12.7364 44.749C8.20789 44.749 5.57975 41.6329 5.57975 37.1146C5.57975 31.0771 11.0382 31.0771 13.7876 34.0764C13.9898 34.2711 14.1515 34.5048 14.3133 34.7385C14.4346 34.8943 14.6367 34.9722 14.7985 34.8554C14.9602 34.7775 15.0006 34.5438 14.9198 34.388C11.5234 28.5063 0 28.8958 0 37.1535C0 42.1003 5.13499 45.567 12.7364 45.567C20.3378 45.567 27.6562 42.0224 27.6562 35.4007C27.6562 29.4022 23.1681 26.8314 17.9522 25.9745H17.9927ZM11.766 25.546H8.20789V12.3805H11.3617C15.8902 12.3805 19.3674 13.8996 19.3674 18.9632C19.3674 23.2479 17.3053 25.546 11.766 25.546Z" fill="white" />
    </g>
    <mask
      id="mask2_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="144"
      height="40"
    >
      <rect y="1" width="143.324" height="39.2727" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask2_21_775)" />
    <g clipPath="url(#clip0_21_775)">
      <path d="M29.1113 11.0578V7.59106H45.7127C42.321 15.7132 37.0252 21.3261 37.0252 29.8648C35.3701 29.2559 33.5564 29.2315 31.8855 29.7957L31.6809 29.8648C31.9044 22.2093 45.4737 13.9592 43.2568 8.94177C42.9638 8.27864 42.2086 8.00759 41.4836 8.00759H39.5899C38.1137 8.00759 37.256 9.67717 38.1157 10.8772C38.1607 10.94 38.1039 11.025 38.0287 11.0075L37.0442 10.7786C34.8297 10.2638 32.5267 10.2638 30.3121 10.7786L29.1113 11.0578Z" fill="white" />
      <path d="M17.9927 22.5874C23.1681 21.8084 25.311 19.4713 25.311 15.5762C25.2706 9.22709 19.8122 8.21436 13.545 8.21436H2.34511V25.314C3.8007 25.1582 5.86278 24.9634 8.20789 25.4308V22.938H12.1703C15.8093 22.938 21.3082 24.5739 21.3082 31.9747C21.3082 37.3889 17.2649 41.3619 12.7364 41.3619C8.20789 41.3619 5.57975 38.2458 5.57975 33.7275C5.57975 27.69 11.0382 27.69 13.7876 30.6893C13.9898 30.884 14.1515 31.1177 14.3133 31.3514C14.4346 31.5072 14.6367 31.5851 14.7985 31.4683C14.9602 31.3904 15.0006 31.1567 14.9198 31.0009C11.5234 25.1192 0 25.5087 0 33.7664C0 38.7132 5.13499 42.1799 12.7364 42.1799C20.3378 42.1799 27.6562 38.6353 27.6562 32.0136C27.6562 26.0151 23.1681 23.4443 17.9522 22.5874H17.9927ZM11.766 22.1589H8.20789V8.99338H11.3617C15.8902 8.99338 19.3674 10.5125 19.3674 15.5762C19.3674 19.8608 17.3053 22.1589 11.766 22.1589Z" fill="white" />
    </g>
    <g clipPath="url(#clip1_21_775)">
      <path d="M29.1113 11.0578V7.59106H45.7127C42.321 15.7132 37.0252 21.3261 37.0252 29.8648C35.3701 29.2559 33.5564 29.2315 31.8855 29.7957L31.6809 29.8648C31.9044 22.2093 45.4737 13.9592 43.2568 8.94177C42.9638 8.27864 42.2086 8.00759 41.4836 8.00759H39.5899C38.1137 8.00759 37.256 9.67717 38.1157 10.8772C38.1607 10.94 38.1039 11.025 38.0287 11.0075L37.0442 10.7786C34.8297 10.2638 32.5267 10.2638 30.3121 10.7786L29.1113 11.0578Z" fill="white" />
      <path d="M17.9927 22.5874C23.1681 21.8084 25.311 19.4713 25.311 15.5762C25.2706 9.22709 19.8122 8.21436 13.545 8.21436H2.34511V25.314C3.8007 25.1582 5.86278 24.9634 8.20789 25.4308V22.938H12.1703C15.8093 22.938 21.3082 24.5739 21.3082 31.9747C21.3082 37.3889 17.2649 41.3619 12.7364 41.3619C8.20789 41.3619 5.57975 38.2458 5.57975 33.7275C5.57975 27.69 11.0382 27.69 13.7876 30.6893C13.9898 30.884 14.1515 31.1177 14.3133 31.3514C14.4346 31.5072 14.6367 31.5851 14.7985 31.4683C14.9602 31.3904 15.0006 31.1567 14.9198 31.0009C11.5234 25.1192 0 25.5087 0 33.7664C0 38.7132 5.13499 42.1799 12.7364 42.1799C20.3378 42.1799 27.6562 38.6353 27.6562 32.0136C27.6562 26.0151 23.1681 23.4443 17.9522 22.5874H17.9927ZM11.766 22.1589H8.20789V8.99338H11.3617C15.8902 8.99338 19.3674 10.5125 19.3674 15.5762C19.3674 19.8608 17.3053 22.1589 11.766 22.1589Z" fill="white" />
    </g>
    <mask
      id="mask3_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="144"
      height="7"
    >
      <rect y="1.64038" width="143.538" height="6.083" fill="#0C0C0C" />
    </mask>
    <g mask="url(#mask3_21_775)">
      <path d="M29.1113 9.68252V6.21582H45.7127C42.321 14.338 37.0252 19.9508 37.0252 28.4896C35.3701 27.8806 33.5564 27.8563 31.8855 28.4205L31.6809 28.4896C31.9044 20.834 45.4737 12.584 43.2568 7.56653C42.9638 6.9034 42.2086 6.63234 41.4836 6.63234H39.5899C38.1137 6.63234 37.256 8.30193 38.1157 9.50196C38.1607 9.56472 38.1039 9.64971 38.0287 9.63223L37.0442 9.40338C34.8297 8.88859 32.5267 8.88859 30.3121 9.40338L29.1113 9.68252Z" fill="white" />
      <path d="M17.9927 20.5597C23.1681 19.7807 25.311 17.4436 25.311 13.5484C25.2706 7.19938 19.8122 6.18665 13.545 6.18665H2.34511V23.2863C3.8007 23.1305 5.86278 22.9357 8.20789 23.4031V20.9102H12.1703C15.8093 20.9102 21.3082 22.5462 21.3082 29.947C21.3082 35.3612 17.2649 39.3342 12.7364 39.3342C8.20789 39.3342 5.57975 36.2181 5.57975 31.6998C5.57975 25.6623 11.0382 25.6623 13.7876 28.6616C13.9898 28.8563 14.1515 29.09 14.3133 29.3237C14.4346 29.4795 14.6367 29.5574 14.7985 29.4406C14.9602 29.3627 15.0006 29.129 14.9198 28.9732C11.5234 23.0915 0 23.481 0 31.7387C0 36.6855 5.13499 40.1522 12.7364 40.1522C20.3378 40.1522 27.6562 36.6076 27.6562 29.9859C27.6562 23.9874 23.1681 21.4166 17.9522 20.5597H17.9927ZM11.766 20.1312H8.20789V6.96567H11.3617C15.8902 6.96567 19.3674 8.48477 19.3674 13.5484C19.3674 17.8331 17.3053 20.1312 11.766 20.1312Z" fill="white" />
    </g>
    <mask
      id="mask4_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="7"
      width="144"
      height="43"
    >
      <path d="M29.1113 16.8805V13.4138H45.7127C42.321 21.536 37.0252 27.1488 37.0252 35.6875C35.3701 35.0786 33.5564 35.0542 31.8855 35.6185L31.6809 35.6875C31.9044 28.032 45.4737 19.782 43.2568 14.7645C42.9638 14.1014 42.2086 13.8303 41.4836 13.8303H39.5899C38.1137 13.8303 37.256 15.4999 38.1157 16.7C38.1607 16.7627 38.1039 16.8477 38.0287 16.8302L37.0442 16.6014C34.8297 16.0866 32.5267 16.0866 30.3121 16.6014L29.1113 16.8805Z" fill="white" />
      <path d="M17.9927 29.7494C23.1681 28.9704 25.311 26.6333 25.311 22.7381C25.2706 16.3891 19.8122 15.3763 13.545 15.3763H2.34511V32.476C3.8007 32.3202 5.86278 32.1254 8.20789 32.5928V30.0999H12.1703C15.8093 30.0999 21.3082 31.7359 21.3082 39.1366C21.3082 44.5509 17.2649 48.5239 12.7364 48.5239C8.20789 48.5239 5.57975 45.4078 5.57975 40.8895C5.57975 34.852 11.0382 34.852 13.7876 37.8513C13.9898 38.046 14.1515 38.2797 14.3133 38.5134C14.4346 38.6692 14.6367 38.7471 14.7985 38.6303C14.9602 38.5524 15.0006 38.3187 14.9198 38.1629C11.5234 32.2812 0 32.6707 0 40.9284C0 45.8752 5.13499 49.3419 12.7364 49.3419C20.3378 49.3419 27.6562 45.7973 27.6562 39.1756C27.6562 33.1771 23.1681 30.6063 17.9522 29.7494H17.9927ZM11.766 29.3209H8.20789V16.1554H11.3617C15.8902 16.1554 19.3674 17.6745 19.3674 22.7381C19.3674 27.0228 17.3053 29.3209 11.766 29.3209Z" fill="white" />
    </mask>
    <g mask="url(#mask4_21_775)">
      <rect y="47.0079" width="143.538" height="6.083" fill="black" />
    </g>
    <mask
      id="mask5_21_775"
      style={{ maskType:'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="47"
      width="144"
      height="7"
    >
      <rect y="47.0078" width="143.538" height="6.083" fill="#0C0C0C" />
    </mask>
    <g mask="url(#mask5_21_775)">
      <path d="M17.9927 29.7494C23.1681 28.9704 25.311 26.6333 25.311 22.7381C25.2706 16.3891 19.8122 15.3763 13.545 15.3763H2.34511V32.476C3.8007 32.3202 5.86278 32.1254 8.20789 32.5928V30.0999H12.1703C15.8093 30.0999 21.3082 31.7359 21.3082 39.1366C21.3082 44.5509 17.2649 48.5239 12.7364 48.5239C8.20789 48.5239 5.57975 45.4078 5.57975 40.8895C5.57975 34.852 11.0382 34.852 13.7876 37.8513C13.9898 38.046 14.1515 38.2797 14.3133 38.5134C14.4346 38.6692 14.6367 38.7471 14.7985 38.6303C14.9602 38.5524 15.0006 38.3187 14.9198 38.1629C11.5234 32.2812 0 32.6707 0 40.9284C0 45.8752 5.13499 49.3419 12.7364 49.3419C20.3378 49.3419 27.6562 45.7973 27.6562 39.1756C27.6562 33.1771 23.1681 30.6063 17.9522 29.7494H17.9927ZM11.766 29.3209H8.20789V16.1554H11.3617C15.8902 16.1554 19.3674 17.6745 19.3674 22.7381C19.3674 27.0228 17.3053 29.3209 11.766 29.3209Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_21_775">
        <rect width="143" height="24" fill="white" transform="translate(0 0.6875)" />
      </clipPath>
      <clipPath id="clip1_21_775">
        <rect width="143" height="12" fill="white" transform="translate(0 26.6875)" />
      </clipPath>
    </defs>
  </svg>
)

export default B7Icon
