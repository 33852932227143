import React, { useCallback, useState } from 'react'

import { IconButton } from 'components/inputs'

import { AccountButton, Logo } from '../../components'
import ConnectButton from 'compositions/PageLayout/components/ConnectButton/ConnectButton'
import { useConnect } from 'web3'
import MobileMenu from '../../components/MobileMenu/MobileMenu'
import { ConnectingContext } from 'local_modules/web3/Connector'
import { Bone } from 'components/feedback'


const Mobile: React.FC = () => {
  const { account } = useConnect()
  const [ isMenuOpen, setMenuOpen ] = useState(false)
  const isConnecting = React.useContext(ConnectingContext)

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <IconButton
          className="mr-12"
          name="interface/menu"
          color="gray-90"
          size={24}
          onClick={handleToggleMenu}
        />
        <Logo />
      </div>
      {isConnecting ? <Bone w={156} h={40} rounded /> : account ? <AccountButton /> : <ConnectButton />}
      {
        isMenuOpen && (
          <MobileMenu handleClose={handleCloseMenu} />
        )
      }
    </div>
  )
}

export default Mobile
