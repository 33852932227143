import { constants, errorNotification, jwt, restAPI, signMessage } from 'helpers'
import { createContext, useContext, useState, useEffect } from 'react'
import { useSWRConfig } from 'swr'
import { useConnect } from 'web3'


type ProfileResult = {
  data: Profile
  error: boolean
  error_message: string
}

type ProfileRequired = Pick<Profile, 'address' | 'email' | 'username' | 'display_name' | 'short_bio'>

 type ChangeAvatarProps = {
   imgUrl: string
   file: File
 }
 type SetAvatarProps = AtLeastOne<ChangeAvatarProps, 'imgUrl' | 'file'>

type State = {
  isFetching: boolean
  profile: Profile
  isExisting: boolean
  refetch: () => Promise<void>
  isSubmitting: boolean
  edit: (profile: Partial<Profile>) => Promise<void>
  editSocials: (profile: Pick<Profile, 'address' | 'links'>) => Promise<void>
  create: (profile: ProfileRequired) => Promise<void>
  sendVerificationEmail: () => Promise<void>
  confirmEmail: (token: string) => Promise<void>
  getToken: () => Promise<string>
  setAvatar: (props: SetAvatarProps) => Promise<void>
}

const Context = createContext<State>(null)

export const useProfile = () => useContext(Context)

export const ProfileProvider = ({ children }) => {
  const { account, library } = useConnect()
  const [ isFetching, setIsFetching ] = useState(true)
  const [ profile, setProfile ] = useState<Profile>()
  const [ isExisting, setisExisting ] = useState<boolean>()
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { mutate: globalMutate } = useSWRConfig()

  const fetch = async () => {
    setIsFetching(true)

    let { token, isExpired, isAccount } = jwt()

    const config = {
      headers: {
        jwt: token,
      },
      timeout: 5000,
    }

    const isTokenValid = !!token && !isExpired && isAccount(account)

    try {
      const { data } = await restAPI.get<ProfileResult>(`/user?address=${account}`, isTokenValid ? config : undefined)

      if (!data.error) {
        setProfile(data.data)
        setisExisting(true)
        setIsFetching(false)
      }
    }
    catch (error) {
      console.log(error.message)

      if (error?.response?.data?.error_message?.includes('not found')) {
        console.log('profile does not exist')
        setisExisting(false)
        setProfile(undefined)
      }

      setIsFetching(false)

      return
    }
  }

  const getToken = async () => {
    try {
      let { token, isExpired, isAccount } = jwt()

      if (!isExpired && token && isAccount(account)) {
        return token
      }

      const { timestamp, signature } = await signMessage(library, account)

      const bodyFormData = new FormData()
      bodyFormData.set('address', account)
      bodyFormData.set('signature', signature)
      bodyFormData.set('timestamp', String(timestamp))

      const { data } = await restAPI.post('/auth', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })

      token = data.data.token as string
      localStorage.setItem(constants.localStorage.token, token)

      return token
    }
    catch (error) {
      if (error?.response?.data?.error_message === 'invalid token') {
        throw new Error('Could not verify your signature. Try again')
      }
      else if (error?.response?.data?.error_message === 'invalid timestamp') {
        throw new Error('Your system time seems to be incorrect. <br /><br /> <a href="https://www.technewstoday.com/set-time-and-date-automatically/" target="_blank">Learn how to fix this</a>')
      }
      else if (error.code === 4001) {
        throw new Error('You denied the signature')
      } {
        throw new Error(error)
      }
    }
  }

  const edit = async (profile: Partial<Omit<Profile, 'links'>>) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = {
        address: account,
        ...profile,
      }

      await restAPI.post('/user', formData, config)
      fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      errorNotification({
        title: 'Couldn\'t save profile',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const editSocials = async (profile: Pick<Profile, 'address' | 'links'>) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      await restAPI.post('/user/links', profile, config)
      fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      errorNotification({
        title: 'Couldn\'t save profile socials',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const create = async (profile: ProfileRequired) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = {
        address: account,
        ...profile,
      }

      await restAPI.post('/user', formData, config)
      fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'

      errorNotification({
        title: 'Couldn\'t create profile',
        text: errorMessage,
      })
      setIsSubmitting(false)
      throw new Error('Couldn\'t create profile')
    }
  }

  const sendVerificationEmail = async () => {
    setIsSubmitting(true)
    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
      }

      await restAPI.post('/mail/send-confirmation', {}, config)

      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      errorNotification({
        title: 'Couldn\'t send confirmation',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const setAvatar = async ({ imgUrl, file }: SetAvatarProps) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = new FormData()
      formData.append('address', account)

      if (file) {
        formData.append('avatar', file)
      }

      if (imgUrl) {
        formData.append('link', imgUrl)
      }

      await restAPI.post('/user/avatar', formData, config)
      fetch()
      const key = `/user?username=${profile.username}`
      globalMutate(key)
      setIsSubmitting(false)
    }
    catch (error) {
      console.log(error)
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      errorNotification({
        title: 'Couldn\'t set profile picture',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const confirmEmail = async (token: string) => {
    try {
      setIsSubmitting(true)
      await restAPI.post(`/mail/confirm?token=${token}`)
      await fetch()
      setIsSubmitting(false)
    }
    catch (err) {
      setIsSubmitting(false)
      throw new Error('Error confirming email')
    }
  }

  const state = {
    isFetching,
    profile,
    isExisting,
    refetch: fetch,
    isSubmitting,
    edit,
    create,
    sendVerificationEmail,
    confirmEmail,
    getToken,
    setAvatar,
    editSocials,
  }

  useEffect(() => {
    if (account) {
      fetch()
    }
  }, [ account ])

  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  )
}
