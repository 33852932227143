import { restAPI, safePromise } from 'helpers'
import { Gallery, Sort } from 'helpers/enums'
import { useQuery } from 'hooks'
import { useState } from 'react'


export enum GalleryTabs {
  All = 'all',
  Active = 'active',
  Upcoming = 'upcoming',
  EndingSoon = 'ending-soon',
  Ended = 'ended'
}

type QueryResult = {
  data: {
    listings: BackendListing[]
    total_count: number
  }
  error: boolean
  error_message: string
}

type Result = Record<GalleryTabs, BackendListing[]>

const useGallery = (name: Gallery) => {
  const url = '/listings'
  const [ sorter, setSorter ] = useState(Sort.Featured)
  const [ endedSorter, setEndedSorter ] = useState(Sort.Newest)

  const fetcher = async () => {
    const galleryQuery = `?curated=${name === Gallery.curated}`
    const liveQuery = '&status=ACTIVE&status=RESOLVING'
    const scheduledQuery = '&scheduled=true'
    const endingSoonQuery = '&trending=true'
    const endedQuery = '&status=SUCCESSFUL&status=FINALIZED'
    const sorterQuery = `&sort=${sorter}`
    const endedSorterQuery = `&sort=${endedSorter}`

    const [ allData, liveData, upcomingData, endingSoonData, endedData ] = await safePromise([
      restAPI.get<QueryResult>(url + galleryQuery + liveQuery + endedQuery + sorterQuery),
      restAPI.get<QueryResult>(url + galleryQuery + liveQuery + sorterQuery),
      restAPI.get<QueryResult>(url + galleryQuery + liveQuery + sorterQuery + scheduledQuery),
      restAPI.get<QueryResult>(url + galleryQuery + liveQuery + sorterQuery + endingSoonQuery),
      restAPI.get<QueryResult>(url + galleryQuery + endedQuery + endedSorterQuery),
    ])

    return {
      [GalleryTabs.All]: allData.data.data.listings,
      [GalleryTabs.Active]: liveData.data.data.listings,
      [GalleryTabs.Upcoming]: upcomingData.data.data.listings,
      [GalleryTabs.EndingSoon]: endingSoonData.data.data.listings,
      [GalleryTabs.Ended]: endedData.data.data.listings,
    }
  }

  const { isFetching, data } = useQuery<Result>({
    endpoint: [ url, name, sorter ],
    fetcher,
  })

  return {
    isFetching,
    data,
    sorter,
    setSorter,
    endedSorter,
    setEndedSorter,
  }
}

export default useGallery
