import { restAPI } from 'helpers'


type Response = {
  data: {
    tx_id: string
  }
  error: boolean
  error_message: string

}

const uploadToArweave = async (file: File) => {
  const formData = new FormData()
  formData.append('mimeType', file.type)
  formData.append('image', file)

  const { data } = await restAPI.post<Response>('/arweave', formData)

  const txId = data.data.tx_id

  return `https://arweave.net/${txId}`
}

export default uploadToArweave
