import React from 'react'
import { modalVisibility, openModal } from '@locmod/modal'
import type { ModalVisibilityProps } from '@locmod/modal'
import { PlainModal } from 'components/feedback'
import s from './AddToCalendarModal.module.scss'
import { Text } from 'components/dataDisplay'
import { constructOutlookUrl, generateGoogleCalendarUrl, generateICSFile, saveICSFile } from 'helpers/calendar'
import { Button, ButtonProps } from 'components/inputs'
import { event as gEvent } from 'helpers'


type CalendarEvent = {
  title: string,
  description: string,
  location: string,
  start: number,
}
const CalendarButton: React.FC<Partial<ButtonProps>> = (props) => {
  return (
    <Button
      title=""
      size={40}
      style="stroked"
      {...props}
    />
  )
}

const AddToCalendarModal: React.FC<ModalVisibilityProps & { title: string, event: CalendarEvent, eventName: string, text?: string }> = (props) => {
  const { title, event, text, eventName, closeModal } = props

  const withAnalytics = (callBack?: () => void) => {
    eventName && gEvent({ event: eventName })
    callBack && callBack()
  }

  return (
    <PlainModal
      closeModal={closeModal}
      className={s.modal}
    >
      <Text className="uppercase text-center" style="h3" message={title} />
      {text && <Text className="mt-16 opacity-06 text-center" style="p2" message={text} />}
      <div className={s.buttons}>
        <CalendarButton title="Apple" leftIcon="calendars/apple" onClick={() => withAnalytics(() => saveICSFile(generateICSFile(event), event.title))} />
        <CalendarButton title="Google" leftIcon="calendars/google" toTab={generateGoogleCalendarUrl(event)} onClick={() => withAnalytics()} />
        <CalendarButton title="iCal file" leftIcon="calendars/ical" onClick={() => withAnalytics(() => saveICSFile(generateICSFile(event), event.title))} />
        <CalendarButton title="Outlook" leftIcon="calendars/outlook" toTab={constructOutlookUrl(event)} onClick={() => withAnalytics()} />
      </div>
    </PlainModal>
  )
}

export const openAddToCalendarModal = ({ title, text, event, eventName } : { title: string, event: CalendarEvent, text?: string, eventName?: string }) => openModal('AddToCalendarModal', { title, event, text, eventName })

export default modalVisibility('AddToCalendarModal', AddToCalendarModal)
