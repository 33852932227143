import React from 'react'
import cx from 'classnames'

import { Header, Footer } from './components'
import PrivacyPolicyWarning from 'compositions/PrivacyPolicyWarning/PrivacyPolicyWarning'

import s from './PageLayout.module.scss'


type PageLayoutProps = {
  className?: string
}

const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const { children, className } = props

  return (
    <main className={cx(s.pageLayout, className)}>
      <Header />
      <div className={s.content}>
        {children}
      </div>
      <Footer />
      <PrivacyPolicyWarning />
    </main>
  )
}


export default PageLayout
