import { Web3Provider } from '@ethersproject/providers'
import { utils } from 'ethers'
import { Field } from 'formular'
import safePromise from './safePromise'
import { errorNotification } from 'helpers/notifications'


export const parseAllowlist = async (textField: string, library: Web3Provider) => {
  const lines = textField.split('\n').filter(Boolean)
  const errorOnLines = []

  const parsed = await safePromise(lines.map(async (line, index) => {
    try {
      if (line.endsWith('.eth')) {
        const resolvedName: string | null = await library.resolveName(line)

        if (!resolvedName) {
          throw new Error(`cannot parse ens name ${line}`)
        }

        return resolvedName
      }

      return utils.getAddress(line)
    }
    catch (error) {
      errorOnLines.push(index + 1)
      console.log(error, `error parsing an address on line ${index}`)
    }
  }))

  return {
    list: parsed.filter(Boolean),
    error: errorOnLines.length ? `Error parsting address on lines: ${errorOnLines.join(', ')}.<br /><br />These addresses will be ignored` : undefined,
  }
}

export const formatAllowlist = async (field: Field<string>, library: Web3Provider) => {
  if (!field.state.value) {
    return
  }

  let value = field.state.value

  value = value.replaceAll('[', '\n')
  value = value.replaceAll(']', '\n')
  value = value.replaceAll(',', '\n')
  value = value.replaceAll(';', '\n')
  value = value.replaceAll('"', '\n')
  value = value.replaceAll(' ', '\n')
  value = value.replaceAll('	', '\n')
  value = value.replaceAll(/\n+/gm, '\n')
  value = value.trim()

  const { list, error } = await parseAllowlist(value, library)

  if (error) {
    errorNotification({
      title: 'Error',
      text: error,
    })
  }

  field.set(value)
}
