import { Button, ButtonProps, Dropdown } from 'components/inputs'
import { event } from 'helpers'
import React, { FC } from 'react'
import WalletsDropdown from '../WalletsDropdown/WalletsDropdown'
import s from './ConnectButton.module.scss'


const ConnectButton: FC<Partial<ButtonProps>> = (props) => {
  const content = <WalletsDropdown />

  return (
    <div>
      <Dropdown
        name="HeaderProfile"
        content={content}
        placement="bottomLeft"
        className={s.container}
      >
        <Button
          className={s.btn}
          size={40}
          style="primary"
          title="Connect wallet"
          onClick={() => event({ event:'connect_wallet' })}
          tag={'div'}
          {...props}
        />
      </Dropdown>
    </div>
  )
}

export default ConnectButton
