import { EventAttributes, createEvent } from 'ics'
import dayjs from 'dayjs'


interface Event {
  title: string;
  description: string;
  location: string;
  start: number;
  duration?: number
}

export const generateICSFile = (event: Event) => {
  const { title, description, location, start, duration } = event

  const startDate = dayjs(start * 1000)

  const eventObject: EventAttributes = {
    start: [ startDate.year(), startDate.month() + 1, startDate.date(), startDate.hour(), startDate.minute() ],
    duration: { hours: duration },
    title,
    description,
    location,
  }
  const { error, value } = createEvent(eventObject)

  if (error) {
    console.error(error)

    return null
  }

  return value
}

export const saveICSFile = (icsFile: string, filename: string) => {
  if (!icsFile) {
    return
  }

  const blob = new Blob([ icsFile ], { type: 'text/calendar;charset=utf-8' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${filename}.ics`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const generateGoogleCalendarUrl = (event: Event): string => {
  const { title, description, location, start, duration } = event

  const startDate = dayjs(start * 1000).format('YYYYMMDDTHHmmss')
  const endDate = dayjs(start * 1000).add(1, 'hours').format('YYYYMMDDTHHmmss')
  const dates = `${startDate}/${endDate}`

  return encodeURI(
    [
      'https://www.google.com/calendar/render?action=TEMPLATE',
      `&text=${title}`,
      `&dates=${dates}`,
      `&details=${description}`,
      `&location=${location}`,
    ].join('')
  )
}

export const constructOutlookUrl = (event: Event): string => {
  const outlookCalendarBaseUrl =
      'https://outlook.office.com/calendar/0/deeplink/compose'
  const outlookCalendarParams = new URLSearchParams({
    subject: event.title,
    body: event.description,
    location: event.location,
    startdt: new Date(event.start * 1000).toISOString(),
    enddt: new Date((event.start * 1000 + event.duration || 1) * 3600).toISOString(),
    path: '/calendar/action/compose',
    rru: 'addevent',
  })

  return `${outlookCalendarBaseUrl}?${outlookCalendarParams.toString()}`
}
