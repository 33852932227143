import React, { useRef, useContext, useState, createContext, useEffect } from 'react'
import { constants } from 'web3'
import localStorage from '@locmod/local-storage'


type State = {
  selectedChainId: number
  setChainId: (chainId: number, changeInMetamask?: boolean) => void
}

export const chains = {
  1: 'Mainnet',
  5: 'Goerli',
  11155111: 'Sepolia',
}

export const Context = createContext<State>(null)

export const requestMetamaskToChangeNetwork = (chainId: number) => {
  return window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: `0x${chainId.toString(16)}`,
      },
    ],
  }) as Promise<any>
}

export const useChainId = () => {
  return useContext(Context)
}

export const ChainIdProvider: React.FC = ({ children }) => {
  const [ selectedChainId, setSelectedChainId ] = useState<number>(null)

  const selectedChainIdRef = useRef<number>(null)
  selectedChainIdRef.current = selectedChainId

  const setChainId = (chainId: number, changeInMetamask = false) => {
    if (selectedChainIdRef.current === chainId) {
      return
    }

    if (constants.supportedChainIds.includes(chainId)) {
      setSelectedChainId(chainId)
      localStorage.setItem(constants.selectedChainIdKey, chainId)

      if (changeInMetamask && chainId !== parseInt(window?.ethereum.chainId, 16)) {
        requestMetamaskToChangeNetwork(chainId)
      }
    }
  }

  useEffect(() => {
    // initial setup
    const chainId = localStorage.getItem<number>(constants.selectedChainIdKey) || constants.preferredChainId
    const isSupported = constants.supportedChainIds.includes(chainId)
    const initialChainId = isSupported ? chainId : constants.preferredChainId

    setSelectedChainId(initialChainId)
    localStorage.setItem(constants.selectedChainIdKey, initialChainId)
  }, [])

  useEffect(() => {
    if (window.ethereum) {
      const handler = (chainId: string) => {
        setChainId(parseInt(chainId))
      }

      window?.ethereum?.on('chainChanged', handler)

      return () => {
        window?.ethereum?.removeListener('chainChanged', handler)
      }
    }
  }, [])

  if (!selectedChainId) {
    setSelectedChainId(+process.env.NEXT_PUBLIC_PREFERRED_CHAIN_ID)
  }

  const contextState = {
    selectedChainId,
    setChainId,
  }

  return (
    <Context.Provider value={contextState}>
      {children}
    </Context.Provider>
  )
}
