import React from 'react'
import { Logo } from 'compositions/PageLayout/components/Header/components'
import { Icon, IconName } from 'components/ui'
import { constants } from 'helpers'


const Mobile: React.FC = () => {
  const SocialLink: React.FC<{ className?: string, icon: IconName, url: string }> = ({ className, icon, url }) => {
    return (
      <a href={url} className={className} target="_blank" rel="noreferrer">
        <Icon name={icon} />
      </a>
    )
  }

  return (
    <div className="flex w-full items-center justify-between">
      <Logo />
      <div className="flex">
        <SocialLink icon="footer/twitter" url={constants.urls.twitter} />
        <SocialLink className="ml-24" icon="footer/instagram" url={constants.urls.instagram} />
        <SocialLink className="ml-24" icon="footer/medium" url={constants.urls.medium} />
      </div>
    </div>
  )
}

export default Mobile
