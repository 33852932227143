import { getTimestamp } from 'helpers'
import type { FC } from 'react'
import React from 'react'
import Avatar from '../Avatar/Avatar'
import Text from '../Text/Text'
import cx from 'classnames'
import s from './ListingCard.module.scss'
import { Bone } from 'components/feedback'
import { useFetchUser } from 'hooks'
import dayjs from 'dayjs'
import Link from 'next/link'


const bannerText = {
  'ACTIVE': 'LIVE',
  'RESOLVING': 'RESOLVING',
  'CANCELED': 'CANCELED',
  'SUCCESSFUL': 'ENDED',
  'FINALIZED': 'ENDED',
}

const Banner: FC<{ listing: BackendListing }> = ({ listing }) => {
  const { time_start, current_tickets, total_tickets, status } = listing || {}

  const isScheduled = getTimestamp() - time_start < 0
  const fromNow = dayjs(time_start * 1000).fromNow()

  const isLive = status === 'ACTIVE' || status === 'RESOLVING'
  const isEnded = status === 'CANCELED' || status === 'SUCCESSFUL' || status === 'FINALIZED'

  const isEndingSoon = current_tickets / total_tickets >= 0.8 && isLive

  let text = bannerText[status]

  if (isScheduled) {
    text = `Live ${fromNow}`
  }
  else if (isEndingSoon) {
    text = 'ending soon'
  }

  const className = cx(
    s.banner,
    {
      [s.ending]: !isScheduled && isEndingSoon,
      [s.scheduled]: isScheduled,
      [s.ended]: isEnded,
    }
  )

  return (
    <div className={className}>
      <Text style="u3" message={text} color="inherit" />
    </div>
  )
}

const Progress: FC<{ listing: BackendListing }> = ({ listing }) => {
  const { total_tickets, current_tickets, status } = listing || {}
  const progress = current_tickets / total_tickets

  if (status !== 'ACTIVE') {
    return null
  }

  return (
    <div className={s.progress}>
      <div style={{ width: `${progress * 100}%` }} />
    </div>
  )
}

type ListingCardProps = {
  className?: string
  listing: BackendListing
  fullWidthOnMobile?: boolean
}

const ListingCard: FC<ListingCardProps> = ({ className, listing, fullWidthOnMobile }) => {
  const { id, creator, content_cache, edition_cache, edition_name, token_name } = listing || {}

  const { isFetching: isUserFetching, data: user } = useFetchUser({ address: creator })

  const { display_name, avatar, username } = user || {}

  return (
    <div className={cx(s.container, className, fullWidthOnMobile && s.fullWidthOnMobile)}>
      <Banner listing={listing} />
      <Link href={`/listing/${id}`}>
        <a title={token_name}>
          <img
            loading="lazy"
            className={cx(s.media)}
            src={edition_cache}
            alt={edition_name}
          />
          <div className={s.raffleImage}>
            <img
              loading="lazy"
              src={content_cache}
              alt={token_name}
            />
          </div>
        </a>
      </Link>
      <div className={s.bar}>
        <Text
          className={s.title}
          style="u3"
          message={edition_name}
          color="white-100"
          tag="p"
        />
        {
          isUserFetching ? (
            <div className="mt-4 flex items-center">
              <Bone w={18} h={18} rounded />
              <Bone className="ml-4" w={100} h={12} rounded />
            </div>
          ) : (
            <Link href={`/user/${username}`}>
              <a className="mt-4 flex items-center hover:underline" title={display_name}>
                <Avatar size={18} src={avatar} address={creator} />
                <Text
                  className={cx('ml-4 opacity-06', s.unset)}
                  style="c3"
                  message={display_name}
                  color="inherit"
                  tag="p"
                />
              </a>
            </Link>
          )
        }
      </div>
      <Progress listing={listing} />
    </div>
  )
}

export default ListingCard
