type EventArgs = {
  event: string
  version?: string
  amount?: string | number
}

const event = (args: EventArgs) => {
  // @ts-ignore
  window.dataLayer?.push({
    ...args,
  })
}

export default event
