import { useChainId } from 'chain-id'
import { constants } from 'web3'


const useScanAddress = (contractAddress: Address, args = '') => {
  const { selectedChainId } = useChainId()

  const chain = constants.chainsMap[selectedChainId]
  const url = chain.blockExplorerUrls[0]

  return url + `/address/${contractAddress}${args}`
}


export default useScanAddress
