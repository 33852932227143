import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'


export enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect'
}

const injected = new InjectedConnector({})

const walletconnect = new WalletConnectConnector({
  infuraId: undefined,
  chainId: +process.env.NEXT_PUBLIC_PREFERRED_CHAIN_ID,
  rpc: {
    1: 'https://eth-mainnet.g.alchemy.com/v2/' + process.env.NEXT_PUBLIC_ALCHEMY_KEY,
    5: 'https://eth-goerli.g.alchemy.com/v2/' + process.env.NEXT_PUBLIC_ALCHEMY_KEY,
    11155111: 'https://eth-sepolia.g.alchemy.com/v2/' + process.env.NEXT_PUBLIC_ALCHEMY_KEY,
  },
  qrcode: true,
})


export default {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
}
