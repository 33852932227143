import React, { FC } from 'react'
import s from './TwitterButton.module.scss'
import cx from 'classnames'
import { Icon } from 'components/ui'
import { constants } from 'helpers'


type TwitterButtonProps = {
  className?: string
}

const TwitterButton: FC<TwitterButtonProps> = ({ className }) => {
  return (
    <a href={constants.urls.twitter} target="_blank" rel="noreferrer" title="Babylon twitter">
      <div className={cx(s.button, className)}>
        <Icon name="footer/twitter" size={20} color="inherit" />
      </div>
    </a>
  )
}

export default TwitterButton
