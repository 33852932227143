const fiteFoxErrors = [
  'NS_ERROR_FAILURE', // has max size
  'NS_ERROR_FILE_CORRUPTED', // is crashed
  'NS_ERROR_FILE_NO_DEVICE_SPACE',
]

// This class is fallback storage when localStorage is blocked
class MemoryStorage implements Storage {
  private value: { [key: string]: any } = {}

  get length(): number {
    return Object.keys(this.value).length
  }

  key(index: number): string | null {
    return Object.keys(this.value)[index] || null
  }

  getItem(key: string): string | null {
    return key in this.value ? (this.value[key] as string) : null
  }

  setItem(key: string, value: string) {
    this.value[key] = value
  }

  removeItem(key: string) {
    delete this.value[key]
  }

  clear(): void {
    this.value = {}
  }

}

class LocalStorage {
  readonly memoryLocalStorage: MemoryStorage
  readonly memorySessionStorage: MemoryStorage

  constructor() {
    if (!this.isSupported(() => localStorage)) {
      this.memoryLocalStorage = new MemoryStorage()
    }

    if (!this.isSupported(() => sessionStorage)) {
      this.memorySessionStorage = new MemoryStorage()
    }
  }

  // we should check it like this, to avoid "Operation Insecure" errors
  // add replace it with MemoryStorage
  private isSupported(getStorage: () => Storage) {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__'
      getStorage().setItem(testKey, testKey)
      getStorage().removeItem(testKey)

      return true
    }
    catch (e) {
      return false
    }
  }

  private getLocalStorage() {
    return this.memoryLocalStorage || localStorage
  }

  private getSessionStorage() {
    return this.memorySessionStorage || sessionStorage
  }

  get<T>(name: string, defaultValue: T, storage: any) {
    if (!storage) {
      console.error('storage is null')

      return defaultValue
    }

    try {
      const value = JSON.parse(storage.getItem(name)) as T

      return value !== null ? value : defaultValue
    }
    catch (err) {
      if (fiteFoxErrors.includes(err.name)) {
        this.clear(storage)

        return defaultValue
      }

      console.error(`localStorage error: name - ${name}`, err)

      this.remove(name, storage) // remove invalid data

      return defaultValue
    }
  }

  set(name: string, value: any, storage: any) {
    if (!storage) {
      console.error('storage is null')

      return
    }

    try {
      storage.setItem(name, JSON.stringify(value))
    }
    catch (err) {
      console.log(name, value)
      console.error('localStorage error', err)

      if (fiteFoxErrors.includes(err.name)) {
        this.clear(storage)
      }
    }
  }

  remove(name: string, storage: any) {
    if (!storage) {
      console.error('storage is null')

      return
    }

    try {
      storage.removeItem(name)
    }
    catch (err) {
      console.log(name)
      console.error('localStorage error', err)
    }
  }

  // to show error alert only once
  corruptedAlertWasShowed = false

  clear(storage: any) {
    // Firefox corrupted file error, try to clean up the storage, and ignore error
    // https://stackoverflow.com/questions/18877643/error-in-local-storage-ns-error-file-corrupted-firefox
    try {
      console.log('clean up storage')

      storage.clear()
    }
    catch (error) {
      if (fiteFoxErrors.includes(error.name) && !this.corruptedAlertWasShowed) {
        alert('Sorry, it looks like your browser storage has been corrupted. '
                    + 'Please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to \'Everything\'. '
                    + 'This will remove the corrupted browser storage across all sites.'
        )

        this.corruptedAlertWasShowed = true
      }

      console.error(error)
    }
  }

  keys(storage: Storage): string[] {
    const length = storage.length
    const result: string[] = []

    for (let i = 0; i < length; i++) {
      result.push(storage.key(i))
    }

    return result
  }

  getItem<T>(name: string, defaultValue?: any): T {
    return this.get(name, defaultValue, this.getLocalStorage()) as T
  }

  setItem(name: string, value: any) {
    this.set(name, value, this.getLocalStorage())
  }

  removeItem(name: string) {
    this.remove(name, this.getLocalStorage())
  }

  getKeys(): string[] {
    return this.keys(this.getLocalStorage())
  }

  getSessionItem<T>(name: string, defaultValue?: T): T {
    return this.get(name, defaultValue, this.getSessionStorage())
  }

  setSessionItem(name: string, value: any) {
    this.set(name, value, this.getSessionStorage())
  }

  removeSessionItem(name: string) {
    this.remove(name, this.getSessionStorage())
  }

  getSessionKeys(): string[] {
    return this.keys(this.getSessionStorage())
  }
}


export default new LocalStorage()
