import { Alchemy, Network } from 'alchemy-sdk'


const networkMap = {
  1: Network.ETH_MAINNET,
  5: Network.ETH_GOERLI,
  11155111: Network.ETH_SEPOLIA,
}

const getAlchemyInstance = (chainId: number) => {
  const config = {
    apiKey: process.env.NEXT_PUBLIC_ALCHEMY_KEY,
    network: networkMap[chainId],
  }

  return new Alchemy(config)
}

export default getAlchemyInstance
