import type { GetOwnersForNftResponse, Nft } from 'alchemy-sdk'
import axios from 'axios'
import safePromise from './safePromise'
import { parseNftInfo } from './parseNftInfo'


type fetchNftInfoProps = {
  chainId: number
  tokenAddress: string
  tokenId: string
}

const fetchNftInfo = async ({ chainId, tokenId, tokenAddress }: fetchNftInfoProps): Promise<NFT> => {
  const queryParams = `?chainId=${chainId}&tokenAddress=${tokenAddress}&tokenId=${tokenId}`

  const [ proxyData, proxyOwner ] = await safePromise([
    axios.get<Nft>('/api/alchemy/metadata' + queryParams),
    axios.get<GetOwnersForNftResponse>('/api/alchemy/owners' + queryParams),
  ])

  const data = proxyData.data
  const contractName = data.contract.name
  const owner = proxyOwner.data.owners[0]
  const attributes = data.rawMetadata?.attributes || []

  const parsedInfo = await parseNftInfo(data, owner)

  return {
    ...parsedInfo,
    contractName,
    attributes,
    owner,
  }
}

export default fetchNftInfo
