import { RefObject, useEffect, useState } from 'react'
import { useOnScreen } from './index'


type UseOnScreen = (
  ref: RefObject<Element>,
  rootMargin?: string,
  time?: number
) => boolean

const UseThrottlingOnScreen: UseOnScreen = (ref, rootMargin = '100px', time = 1000) => {
  const [ isActive, setIsActive ] = useState(false)
  const isVisible = useOnScreen(ref, rootMargin)

  useEffect(() => {
    setTimeout(() => {
      setIsActive(isVisible)
    }, time)
  }, [ isVisible ])

  return isActive
}

export default UseThrottlingOnScreen
