import axios from 'axios'


const fetchNftJson = async (url: string) => {
  try {
    const { data: nftJson } = await axios.get<NFTJson>(url)

    return nftJson
  }
  catch (error) {
    console.warn(`failed to fetch nft info from ${url}`)

    return null
  }
}

export default fetchNftJson
