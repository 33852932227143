import React from 'react'
import { useConnect } from 'web3'

import { AccountButton, Logo, Navbar } from '../../components'
import { ConnectButton } from 'compositions/PageLayout/components'
import TwitterButton from '../../components/TwitterButton/TwitterButton'
import { ConnectingContext } from 'local_modules/web3/Connector'
import { Bone } from 'components/feedback'


const DesktopHeader: React.FC = () => {
  const { account } = useConnect()
  const isConnecting = React.useContext(ConnectingContext)

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Logo />
      </div>
      <Navbar />
      <div className="flex">
        <TwitterButton className="mr-12" />
        {isConnecting ? <Bone w={202} h={44} rounded /> : account ? <AccountButton /> : <ConnectButton />}
      </div>
    </div>
  )
}

export default DesktopHeader
