import { Web3Provider } from '@ethersproject/providers'
import { utils } from 'ethers'
import getTimestamp from './getTimestamp'


const signMessage = async (library: Web3Provider, account: string) => {
  const timestamp = getTimestamp()
  const message = `I want to sign in to Babylon.art at timestamp ${timestamp}`
  const messageHex = utils.hexlify(utils.toUtf8Bytes(message))

  // const signature = await window.ethereum.request({ method: 'personal_sign', params: [ messageHex, account ] })
  // above method doesn't work with walletconnect
  const signature = await library.send('personal_sign', [ messageHex, account ])

  return { timestamp, signature }
}

export default signMessage
