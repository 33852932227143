import React from 'react'
import { useDevice } from 'device'
import cx from 'classnames'

import { WidthContainer } from 'components/layout'
import Desktop from './views/Desktop/Desktop'
import Mobile from './views/Mobile/Mobile'

import s from './Footer.module.scss'


const Footer: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <footer className={cx(s.footer, isMobile ? 'mt-32' : '')}>
      <WidthContainer className={s.content}>
        {isMobile ? <Mobile /> : <Desktop />}
      </WidthContainer>
    </footer>
  )
}

export default Footer
