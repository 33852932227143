import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'
import React, { FC, useState, useEffect } from 'react'
import { useConnect, ConnectorNames } from 'web3'


import s from './WalletsDropdown.module.scss'


export enum Wallet {
  Metamask = 'metamask',
  WalletConnect = 'walletconnect'
}


export type ConnectorsByName = {
  [connectorName in ConnectorNames]: {
    wallet: Wallet
    title: string
    connector: ConnectorNames
  }
}

const connectorsByName: ConnectorsByName = {
  [ConnectorNames.Injected]: {
    wallet: Wallet.Metamask,
    title: 'MetaMask',
    connector: ConnectorNames.Injected,
  },
  [ConnectorNames.WalletConnect]: {
    wallet: Wallet.WalletConnect,
    title: 'WalletConnect',
    connector: ConnectorNames.WalletConnect,
  },
}

type WalletButtonProps = {
  title: string
  connector: ConnectorNames
  wallet: Wallet
  onClick: (connector: ConnectorNames) => void
}

const WalletButton: FC<WalletButtonProps> = ({ title, connector, wallet, onClick }) => {
  return (
    <div className={s.button} onClick={() => onClick(connector)}>
      <img width={22} height={22} src={`/images/icons/wallets/${wallet}.svg`} alt={wallet} />
      <Text className="ml-8" style="p2" message={title} />
    </div>
  )
}

const WalletsDropdown: FC = () => {
  const { connect } = useConnect()
  const [ isConnecting, setIsConecting ] = useState<boolean>(false)

  const handleConnect = async (connector: ConnectorNames) => {
    if (isConnecting) {
      return
    }

    setIsConecting(true)

    try {
      await connect(connector)
      setIsConecting(false)
    }
    catch (err) {
      if (err.message.includes('No Ethereum provider was found')) {
        const newWindow = window.open('https://metamask.io/download/', '_blank', 'noopener,noreferrer')

        if (newWindow) {
          newWindow.opener = null
        }
      }

      setIsConecting(false)
    }
  }

  return (
    <div className="my-4">
      {
        Object.keys(connectorsByName).map((name) => {
          const { wallet, title, connector } = connectorsByName[name]

          return (
            <WalletButton
              key={title}
              wallet={wallet}
              title={title}
              connector={connector}
              onClick={handleConnect}
            />
          )
        })
      }
    </div>
  )
}

export default WalletsDropdown
