export default {
  origin: process.env.NEXT_PUBLIC_URL,
  secondsInDay: 86_400,
  localStorage: {
    connectorName: 'babylon-connector-name',
    token: 'babylon-jwt-token',
    skippedOnboarding: 'babylon-onboarding-skip',
    onboardingEmail: 'babylon-email',
    grid: 'babylon-nft-grid',
    policyConcent: 'babylon-policy-content',
    lengthWarning: 'babylon-length-warning',
  },
  nullAddress: '0x0000000000000000000000000000000000000000',
  chainNames: {
    1: 'Ethereum',
    5: 'Görli',
    11155111: 'Sepolia',
  },
  operatorFilters: {
    1: '0x1dE06D2875453a272628BbB957077d18eb4A84CD',
    5: '0x851b63Bf5f575eA68A84baa5Ff9174172E4d7838',
    11155111: '0x0000000000000000000000000000000000000000',
  },
  urls: {
    docs: 'https://docs.babylon.art/',
    privacyPolicy: 'https://docs.babylon.art/babylon-info/general-information/privacy-policy',
    termsOfService: 'https://docs.babylon.art/babylon-info/general-information/terms-of-use-of-service',
    support: 'https://twitter.com/babylon_support',
    medium: 'https://medium.com/@0xBabylon',
    twitter: 'https://twitter.com/0xBabylon',
    instagram: 'https://instagram.com/0xBabylon',
    getVerifiedForm: 'https://maufxc0zezm.typeform.com/babylon-verify',
    whitelistNFTForm: 'https://maufxc0zezm.typeform.com/to/EIqVPJsJ',
  },
}
