export const toBps = (value: number) => {
  if (value < 0 || value > 1) {
    throw new Error('value must be between 0 and 1')
  }

  return value * 10000
}

export const fromBps = (bps: number) => {
  if (bps < 0 || bps > 10000) {
    throw new Error('value must be between 0 and 10000')
  }

  return bps / 10000
}
