import React, { FC, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import 'animate.css'
import { useOnScreen } from 'hooks'


type Animations = 'fadeInDown' | 'fadeInUp' | 'fadeInLeft' | 'fadeInRight'

type AnimatedProps = {
  className?: string
  type?: Animations
}

const Animated: FC<AnimatedProps> = ({ className, children, type = 'fadeInDown' }) => {
  const [ animated, setAnimated ] = useState(false)
  const [ isShown, setIsShown ] = useState(false)
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  // applied classes mess up with z-index and has to be removed after animation is done
  useEffect(() => {
    let timeoutId

    if (isVisible && !animated) {
      timeoutId = setTimeout(() => {
        setAnimated(true)
      }, 1000)
    }

    return () => clearTimeout(timeoutId)
  }, [ isVisible ])

  // isVisible can bounce, set it once
  useEffect(() => {
    if (isVisible) {
      setIsShown(true)
    }
  }, [ isVisible ])

  return (
    <div className={cx(className, isShown && !animated && `animate__animated animate__${type}`)} ref={ref}>
      {children}
    </div>
  )
}

export default Animated
