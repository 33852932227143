import { useChainId, chains } from 'chain-id'
import { constants } from 'web3'


const testnetChains = [ 5 ]

type MarketplaceUrlProps = {
  tokenAddress: string
  tokenId: string
}

type MarketplaceUrlResults = {
  opensea: string
  looksrare: string
}

const useMarketplaceUrl = ({ tokenAddress, tokenId }: MarketplaceUrlProps): MarketplaceUrlResults => {
  const { selectedChainId } = useChainId()

  const isTestnet = testnetChains.includes(selectedChainId)
  const chainName = constants.chainsMap[selectedChainId].chainName.toLowerCase()

  // opensea
  const testnetPrefix = isTestnet ? 'testnets.' : ''
  const opensea = `https://${testnetPrefix}opensea.io/assets/${chainName}/${tokenAddress}/${tokenId}`

  // looksrare
  const lrTestnetPrefix = isTestnet ? `${chainName}.` : ''
  const looksrare = `https://${lrTestnetPrefix}looksrare.org/collections/${tokenAddress}/${tokenId}`

  return {
    opensea,
    looksrare,
  }
}

export default useMarketplaceUrl
