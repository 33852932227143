import React, { FC } from 'react'
import cx from 'classnames'
import { Text } from 'components/dataDisplay'
import s from './OnboardingBanner.module.scss'
import { WidthContainer } from 'components/layout'
import { Button } from 'components/inputs'
import { useRouter } from 'next/router'
import { useConnect } from 'web3'
import { useProfile } from 'context'
import { useDevice } from 'device'


type OnboardingBannerProps = {
  className?: string
}

const OnboardingBanner: FC<OnboardingBannerProps> = ({ className }) => {
  const router = useRouter()
  const { account } = useConnect()
  const { isExisting } = useProfile()
  const { isMobile } = useDevice()

  if (
    !account ||
    router.asPath === '/onboarding' ||
    router.asPath.startsWith('/profile/confirm') ||
    isExisting === true ||
    isExisting === undefined
  ) {
    return null
  }

  const onboardingText = 'Create your profile to unlock all Babylon.art features.'

  return (
    <div className={cx(s.container, className)}>
      <WidthContainer className={cx('flex items-center justify-between', isMobile && 'flex-col')}>
        <Text style="sub" message={onboardingText} color="white-100" />
        <Button
          className={isMobile ? 'mt-16' : 'ml-8'}
          size={32}
          title="go to Onboarding"
          style="action"
          onClick={() => router.push('/onboarding')}
          fullWidthOnMobile
        />
      </WidthContainer>
    </div>
  )
}

export default OnboardingBanner
