import { openNotification } from '@locmod/notifications'
import React from 'react'


interface notificationProps {
  title: string
  text?: string
  children?: React.ReactNode
}

const successTransaction = {
  title: 'Transaction success',
  text: 'Your transaction was approved',
}

const failureTransaction = {
  title: 'Transaction error',
  text: 'Your transaction failed',
}

export const defaultNotification = (props: notificationProps) => openNotification('plain', props) as void

export const errorNotification = (props: notificationProps) => openNotification('plain', { ...props, error: true }) as void

export const txSuccessNotification = () => defaultNotification(successTransaction)

export const txFailureNotification = () => errorNotification(failureTransaction)

