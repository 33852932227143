import { useState, useEffect, useCallback } from 'react'


function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
}

const getMedia = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const isIOS = iOS()

  return {
    isMobile: width < 1000,
    isIOS,
  }
}

export const useDevice = () => {
  const [ isMounted, setIsMounted ] = useState(true)
  const [ state, setState ] = useState({
    isMobile: false,
    isIOS: false,
  })

  const handleResize = useCallback(() => {
    const media = getMedia()

    document.documentElement.classList.remove('desktop', 'mobile')
    document.documentElement.classList.add(media.isMobile ? 'mobile' : 'desktop')

    isMounted && setState(media)
  }, [ isMounted ])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      setIsMounted(false)
    }
  }, [])

  return state
}
