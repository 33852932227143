import React, { FC, useState, useEffect } from 'react'
import { Text } from 'components/dataDisplay'
import { constants, localStorage } from 'helpers'
import cx from 'classnames'
import s from './PrivacyPolicyWarning.module.scss'
import { useDevice } from 'device'
import { Button } from 'components/inputs'


const PrivacyPolicyWarning: FC = () => {
  const [ isAccepted, setAccepted ] = useState(true)
  const { isMobile } = useDevice()

  useEffect(() => {
    setAccepted(localStorage.getItem<boolean>(constants.localStorage.policyConcent, false))
  }, [])

  if (isAccepted) {
    return null
  }

  const handleAccept = () => {
    localStorage.setItem(constants.localStorage.policyConcent, true)
    setAccepted(true)
  }

  return (
    <div className={cx(s.warning, s.visible)}>
      <div className="flex items-center">
        <Text
          color="inherit"
          style="c2"
          message="This website uses cookies to ensure you get the best experience on our website."
        />
        <Button
          className={s.btn}
          size={32}
          title="Okay"
          style="action"
          onClick={handleAccept}
        />
      </div>
    </div>
  )
}

export default PrivacyPolicyWarning
