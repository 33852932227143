import { useQuery } from 'hooks'
import { useConnect } from 'web3'
import { useContracts } from 'contracts/client'
import { formatEther } from '@ethersproject/units'


const useBalance = () => {
  const { account } = useConnect()
  const { getProvider } = useContracts()

  const fetcher = async () => {
    const rawBalance = await getProvider().getBalance(account)

    return formatEther(rawBalance)
  }

  const { isFetching, data } = useQuery({
    endpoint: [ '/balance', account ],
    fetcher,
    skip: !account,
  })

  return {
    isBalanceFetching: isFetching,
    balance: data,
  }
}

export default useBalance
