import React, { useContext, useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, JsonRpcProvider, AlchemyProvider, InfuraProvider } from '@ethersproject/providers'
import type { ContractInterface } from '@ethersproject/contracts'
import { useConnect } from 'web3'
import { useChainId } from 'chain-id'

import { Providers } from './enums'


type GetCustomProps<Contracts> = ({ addresses, provider }: { addresses: Record<keyof Contracts, string>, provider: JsonRpcSigner | JsonRpcProvider }) => Record<string, any>

type ContextContracts<Contracts> = {
  [K in keyof Contracts as `${string & K}Contract`]: Contracts[K]
}

type Result<Contracts, U extends GetCustomProps<Contracts>> = {
  ContractsProvider: React.FC
  useContracts: () => ContextState<Contracts, U>
}

type Props<Contracts> = {
  addressesByChains: {
    [chainId: number]: Record<keyof Contracts, string>
  }
  abis: {
    [K in keyof Contracts]: ContractInterface
  }
  infuraKey?: string
  alchemyKey?: string
  enforcedProvider?: Providers
}

type ContextState<Contracts, U extends GetCustomProps<Contracts>> = ContextContracts<Contracts> & ReturnType<U> & {
  selectedChainId: number
  getProvider: () => JsonRpcSigner | JsonRpcProvider
}

export function setup<Contracts>() {
  return <U extends GetCustomProps<Contracts>>(props: Props<Contracts>, getCustomProps?: U): Result<Contracts, U> => {
    const { addressesByChains, abis, infuraKey, alchemyKey, enforcedProvider } = props

    const existingAddresses = Object.values(addressesByChains).find(Boolean)
    const contractNames = Object.keys(existingAddresses)

    const getClientProvider = (chainId: number): JsonRpcProvider => {
      if (enforcedProvider === Providers.Infura) {
        return new InfuraProvider(chainId, infuraKey)
      }

      if (chainId === 11155111) {
        return new JsonRpcProvider(`https://eth-sepolia.g.alchemy.com/v2/${alchemyKey}`)
      }

      return new AlchemyProvider(chainId, alchemyKey)
    }

    const Context = React.createContext<ContextState<Contracts, U>>(null)

    const useContracts = () => {
      return useContext(Context)
    }

    const ContractsProvider = ({ children }) => {
      const { library, account, isRightNetwork } = useConnect()
      const { selectedChainId } = useChainId()

      const context = useMemo(() => {
        const provider = getClientProvider(selectedChainId)
        const addresses = addressesByChains[selectedChainId]
        const context = {} as ContextState<Contracts, U>

        context.selectedChainId = selectedChainId

        contractNames.forEach((name) => {

          if (addresses[name]) {
            // @ts-ignore
            context[`${name}Contract`] = new Contract(addresses[name], abis[name], provider)
          }
        })

        context.getProvider = () => {
          return getClientProvider(selectedChainId)
        }

        if (getCustomProps) {
          const customProps = getCustomProps({ addresses, provider })

          Object.keys(customProps).forEach((propName) => {
            // @ts-ignore
            context[propName] = customProps[propName]
          })
        }

        return context
      }, [ library, account, selectedChainId ])

      return (
        <Context.Provider value={context}>
          {children}
        </Context.Provider>
      )
    }

    return {
      ContractsProvider,
      useContracts,
    }
  }
}
