import React from 'react'
import cx from 'classnames'

import { Icon } from 'components/ui'
import type { IconProps } from 'components/ui/Icon/Icon'

import s from './IconButton.module.scss'


type IconButtonProps = React.HTMLProps<HTMLButtonElement> & IconProps

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { className, name, size, onClick, color } = props

  return (
    <button
      className={cx(s.iconButton, className)}
      type="button"
      onClick={onClick}
    >
      <Icon name={name} size={size} color={color} />
    </button>
  )
}


export default IconButton
